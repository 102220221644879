


export const isAllDhivehi = (text) => {
    const dhivehiRegex = /^[\u0780-\u07B1\u07C0-\u07FA\d\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]+$/;
    return dhivehiRegex.test((text || '').replace(/[^a-zA-Z\u0780-\u07B1\s]+/g, ''));
}


export const isDhivehi = (text) => {
    if (typeof text !== "string") return false
    const alphaText = text.replace(/[\s\p{P}\d]/gu, '');
    const matches = alphaText.match(/[\u0780-\u07BF]/g);
    if (matches === null) return false
    return matches && (matches.length / alphaText.length > 0.5);
}