import React from 'react';
import {  Modal } from "reactstrap";
import { Loader } from './Loader';
import { CaseContent } from './CaseContent';
import { themeTypeState } from '../../state/GlobalState';
import { useRecoilValue } from 'recoil';


export const CaseModal = ({visible, toggle, data, searchedQuery="", initialTab=1, size="xl", fullscreen=true, loading=false}) => {    

    const themeType = useRecoilValue(themeTypeState);

    return (
        <Modal size={size} isOpen={visible} toggle={()=>toggle(false)} keyboard={false} fullscreen={fullscreen}>
            <Loader loading={loading} />
            <div className={"modal-header"+(themeType === "dark" ? " dark-modal" : "")} style={{backgroundColor:"rgba(0,0,0,0.04)"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">{data?.title || "ޤަޒިއްޔާ"}</h5>
                <button type="button" onClick={() => toggle(false)} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <React.Fragment>
                <div id="modalBody" className={"modal-body"+(themeType === "dark" ? " dark-modal" : "")} style={{padding:0}}>
                    <CaseContent data={data} searchedQuery={searchedQuery} initialTab={initialTab} visible={visible} />
                </div>
                {/* <div className="modal-footer" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
                    <button type="button" onClick={() => toggle(false)} className="btn btn-secondary waves-effect">ކްލޯސް</button>
                </div> */}
            </React.Fragment>
        </Modal>
    )

}