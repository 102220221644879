import React, { useEffect, useState } from 'react';

import { Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
import profile from "../../assets/images/logo-dark.png";
import profileLight from "../../assets/images/logo-light-1.png";
import logo from "../../assets/images/logo.svg";
import bgImage from "../../assets/images/law-background.jpg"
import { useRecoilState, useRecoilValue } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { themeTypeState } from '../../state/GlobalState';
import { Loader } from '../../custom/components/Loader';
import { completeEmail, completeRegistration, initEmail } from '../../api/controller/RegisterController';

const Register = ({history,handleAuthResponse}) =>  {
    const [error, setError] = useRecoilState(loginErrorState)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const themeType = useRecoilValue(themeTypeState)
    const [form, setForm] = useState({
        Email: '',
        EmailReference: '',
        EmailOtp: '',
        Fullname: '',
        Password: '',
        Password_confirmation: '',
    })
    const [currentStep, setCurrentStep] = useState('enter-email') // enter-email, enter-otp, final

    // handleValidSubmit
    const handleValidSubmit = async (event) => {
        event.preventDefault()
        setSubmitDisabled(true)
        let response = null
        if(currentStep === 'enter-email'){
            response = await initEmail({ 'Email': form.Email })
            if(response?.Reference){
                setForm({...form, EmailReference: response.Reference})
                setCurrentStep('enter-otp')
            }
        } else if(currentStep === 'enter-otp'){
            response = await completeEmail({ EmailReference: form.EmailReference, EmailOtp: form.EmailOtp })
            if(response?.Status === 'success'){
                setCurrentStep('final')
            }
        } else if(currentStep === 'final'){
            response = await completeRegistration(form)
        }
        console.log(response)
        
        if(response.decodedToken){
            handleAuthResponse(response, response.encodedToken, false, true)
        }  else if (response?.Status === "error") {
            if (response.payload?.errors) {
              Object.keys(response.payload.errors).forEach(function (key) {
                response.payload.errors[key].forEach((val) => {
                  setError(val);
                });
              });
            } else {
              setError(response.Message);
            }
            setSubmitDisabled(false);
        } else {
            setError("")
        }
        setSubmitDisabled(false)
    }

    useEffect(() => {
        setError("");
    }, [setError])

    return (
        <React.Fragment>
            {/* <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
            </div> */}
            
            <div><img src={bgImage} alt="bg-img" style={{ zIndex: "-10", position: "fixed", top:0, opacity: 0.8, objectFit: "cover", right:'-10px'}} /></div>
            <div className="account-pages my-5 pt-sm-5">
                <Loader loading={submitDisabled} transparent />
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8} lg={6} className='p-0'>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className={"p-4 " + (themeType === 'dark' ? 'text-white' : 'text-primary')}>
                                                <h5 className={(themeType === 'dark' ? 'text-white' : 'text-primary')}>ޝުނާ.ލޯ</h5>
                                                <p>ރަޖިސްޓާ ކުރައްވާ</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-center">
                                            <img width="90%" src={profile} alt="shuna.law" className="img-fluid dark-logo" />
                                            <img width="90%" src={profileLight} alt="shuna.law" className="img-fluid light-logo" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="34" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">

                                        <Form className="form-horizontal" onSubmit={handleValidSubmit}>

                                            {error ? <Alert className='dvl' color="danger">{error}</Alert> : null}
                                            <FormGroup>
                                                <Label>އީމެއިލް</Label>
                                                <Input value={form.Email} onChange={(e)=>setForm({...form, Email: e.target.value})} disabled={currentStep !== 'enter-email'} name="Email" className="form-control enf" placeholder="Email" type="email" required />
                                            </FormGroup>
                                            {currentStep === 'enter-otp' &&
                                                <FormGroup>
                                                    <Label>އީމެއިލް އަށް ފޮނުވުނު އޯޓިޕީ ޖައްސަވާ</Label>
                                                    <Input value={form.EmailOtp} onChange={(e)=>setForm({...form, EmailOtp: e.target.value})} name="EmailOtp" className="form-control enf" placeholder="Enter OTP sent to Email" type="text" required />
                                                </FormGroup>
                                            }
                                            {currentStep === 'final' &&
                                                <>
                                                    <FormGroup>
                                                        <Label>ފުރިހަމަ ނަން</Label>
                                                        <Input value={form.Fullname} onChange={(e)=>setForm({...form, Fullname: e.target.value})} name="Email" className="form-control enf" placeholder="Full Name" type="text" required />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>ޕާސްވޯޑް</Label>
                                                        <Input value={form.Password} onChange={(e)=>setForm({...form, Password: e.target.value})} name="Password" type="password" required placeholder="Password" />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>ޕާސްވޯޑް އަލުން ޖައްސަވާ</Label>
                                                        <Input value={form.Password_confirmation} onChange={(e)=>setForm({...form, Password_confirmation: e.target.value})} name="Password_confirmation" type="password" required placeholder="Confirm Password" />
                                                    </FormGroup>
                                                </>    
                                            }

                                            <div className="mt-4">
                                                <button disabled={submitDisabled} className="btn btn-primary btn-block waves-effect waves-light w-100" type="submit">{currentStep !== 'final' ? 'ކުރިޔަށް' : 'ރަޖިސްޓާ'}</button>
                                                <p align="center" className='mt-3 mb-3'>- އެކައުންޓެއް އެބައިން؟ -</p>
                                                <button disabled={submitDisabled} className="btn btn-secondary btn-block waves-effect waves-light w-100" type="button" onClick={()=>history.push("/login")}>އަނބުރާ ލޮގިން ޕޭޖަށް</button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Register);
