import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getCourtListRequest } from "../../../api/controller/AnalysisController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import { Badge } from "reactstrap";

export const analysisListState = atom({
    key: 'analysisListState',
    default: [],
})
export const analysisCourtState = atom({
	key: 'analysisCourtState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "xl", wizard: true }

export const analysisFilterCourtValueState = atom({
    key: 'analysisFilterCourtValueState',
    default: { label: "ސުޕްރީމް ކޯޓު", value: 1 },
})

export const analysisStatusListState = atom({
	key: 'analysisStatusListState',
	default:{
		data: [
            { label: 'Pending', value: 'Pending' },
            { label:  'Analyzed', value:  'Analyzed' },
            { label:  'Scanned', value:  'Scanned' },
            { label:  'Finalized', value:  'Finalized' },
            { label:  'Cancelled', value:  'Cancelled' },
        ],
		ovf:false,
	}
})
export const analysisFilterStatusValueState = atom({
    key: 'analysisFilterStatusValueState',
    default: { label: "Pending", value: "Pending" },
})

export const analysisFilterState = selector({
    key: 'analysisFilterState',
    get: ({ get }) => [
        {
            label: 'Court',
            displayLabel: 'ކޯޓު',
            size: 6,
            required: true,
            type: 'select',
            dv: true,
            value: get(analysisFilterCourtValueState),
            list: get(analysisCourtState),
        },
        {
            label: 'Status',
            displayLabel: 'ސްޓޭޓަސް',
            size: 6,
            required: true,
            type: 'select',
            value: get(analysisFilterStatusValueState),
            list: get(analysisStatusListState),
        }
    ],
    set: ({ set }, e) => {
        if(e.filterLabel === 'Court'){
            set(analysisFilterCourtValueState, e.value)
        } else if(e.filterLabel === 'Status'){
            set(analysisFilterStatusValueState, e.value)
        }
    }
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const analysisDataStructureState = selector({
    key: 'analysisDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'ހުކުމް ނަމްބަރު',
                name: 'JudgementNumber',
                field: 'judgement_number',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                table: { maxWidth: 150 },
                order: { form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ކޯޓް',
                name: 'Court',
                field: 'court',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'select',
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(analysisCourtState),
				getRequest: getCourtListRequest,
            },
			{ 
                label: 'ޓައިޓްލް',
                name: 'Title',
                field: 'title',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'މައްސަލަ ހުޝައެޅި ފަރާތް',
                name: 'Claimant',
                field: 'claimant',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'މައްސަލަ ރައްދުވާ ފަރާތް',
                name: 'Respondant',
                field: 'respondant',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ދުވަސް',
                name: 'Date',
                field: 'date',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'datepicker',
                order: { table: 2.1, form: 6 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD', maxWidth: 150 },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'މައްސަލާއިގެ ބާވަތް',
                name: 'CaseType',
                field: 'case_type',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ކީވޯޑްތައް',
                name: 'Keywords',
                field: 'keywords',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޕީޑީއެފް ފައިލް',
                name: 'PdfFile',
                field: 'pdf_file',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'file',
                order: { form: 13 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ކޯޓު ފައިލް ލިންކް',
                name: 'ExtFile',
                field: 'ext_file',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { form: 13.1 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'ސަމާލުވާން ޖެހޭ ކަންތައްތައް',
                name: 'AnalysisWarnings',
                field: 'analysis_warnings',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'repeater',
                order: { form: 21 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }],
                children: [
                    { 
                		label: 'ތަފްސީލު',
                        name: 'Description',
                        field: 'description',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }], 
            		},
					
                ]
            },
			{ 
                label: 'ޕޭޖްގެ އަދަދު',
                name: 'PageCount',
                field: 'page_count',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'number',
                order: { form: 9 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 3 }  }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'ސްކޭން ޕޭޖްގެ އަދަދު',
                name: 'ScannedPageCount',
                field: 'scanned_page_count',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'number',
                order: { form: 10 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 3 }  }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'ފަށާ ޕޭޖް ނަމްބަރު',
                name: 'ContentStartPage',
                field: 'content_start_page',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'number',
                order: { table: 4.9, form: 11 }, 
                table: { maxWidth: 150 },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 3 }  }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'ޓޭބަލްގެ އަދަދު',
                name: 'TablesDetected',
                field: 'tables_detected',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'number',
                order: { form: 12 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 3 }  }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'އެނެލައިޒަ ކުރީ',
                name: 'AnalyzedBy',
                field: 'analyzed_by',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'select',
                order: { form: 14 }, 
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], 
            },
			{ 
                label: 'އެނެލައިޒް ކުރެވޭ ޕޭޖްތައް',
                field: 'analyzable_page_count',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'text',
                order: { table: 4.9 }, 
                table: { maxWidth: 175 },
                create: [{ enabled: false }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'ފަނޑިޔާރުންގެ ލިސްޓު',
                name: 'AnalysisJudges',
                field: 'analysis_judges',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'repeater',
                order: { form: 18 },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], view: ['same_as:update.0'],
                children: [
                    { 
                		label: 'ނަން',
                        name: 'Name',
                        field: 'name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:update.0'], 
            		},
					
                ]
            },
			{ 
                label: 'ޤަޒިއްޔާ ނަންބަރުތައް',
                name: 'AnalysisCaseNumbers',
                field: 'analysis_case_numbers',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'repeater',
                order: { form: 19 },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], view: ['same_as:update.0'],
                children: [
                    { 
                		label: 'ނަންބަރު',
                        name: 'Number',
                        field: 'number',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:update.0'], 
            		},
					
                ]
            },
			{ 
                label: 'ކޮންޓެންޓް',
                name: 'Content',
                field: 'case_content',
                section: { id: 3, label: 'ކޮންޓެންޓް' },
                type: 'tinymce',
                order: { form: 19.1 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, height: '60vh' }], view: [{ enabled: false }], 
            },
			{ 
                label: 'ފައިނަލައިޒް؟',
                name: 'Finalized',
                field: 'is_finalized',
                section: { id: 3, label: 'ކޮންޓެންޓް' },
                type: 'switch',
                order: { form: 19.11 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 } }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'ސްޓޭޓަސް',
                name: 'Status',
                field: 'status',
                section: { id: 2, label: 'އެނޭލިސިް' },
                type: 'select',
                order: { form: 16 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Pending', value: 'Pending' },
						{ label:  'Analyzed', value:  'Analyzed' },
                        { label:  'Scanned', value:  'Scanned' },
						{ label:  'Finalized', value:  'Finalized' },
						{ label:  'Cancelled', value:  'Cancelled' },
					]
				},
            },
			{ 
                label: 'ކޮންފިޑެންސް ލެވެލް',
                name: 'ConfidenceLevel',
                field: 'confidence_level',
                type: 'text',
                order: { table: 5 }, 
                table: { maxWidth: 150, customRender: (value, params) => (
                    <>
                        {params.data["warning_count"] > 0 ? 
                            <Badge title="Warnings" color='warning' className={"bg-warning font-size-14"} style={{padding: "5px 10px"}}>
                                {params.data["warning_count"] || 0}
                            </Badge> 
                        : null }
                        &nbsp;&nbsp;{value}
                    </>
                ) },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: false }], 
            },
            { 
                label: 'ޤަޒިއަޔާ ނަމްބަރު',
                name: 'CaseNumbers',
                field: 'case_numbers_text',
                type: 'text',
                table: { maxWidth: 150 },
                order: { table: 1 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
