
import React, { useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { handleResponse } from "../helpers/HandleResponse";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { pageWebsocketsEnabledState, lastAPIActionTimeState } from "../../state/GlobalState";
import { Loader } from "./Loader";
// import { useState } from "react";

export const ConfirmDialog = ({id, params, config, visible, toggleModal}) => {

    const setLastAPIActionTime = useSetRecoilState(lastAPIActionTimeState)
    const pageWebsocketsEnabled = useRecoilValue(pageWebsocketsEnabledState)
    const [confirmDisabled, setConfirmDisabled] = useState(false)

    const confirm = async () => {
        setConfirmDisabled(true)
        const data = params && Object.keys(params).length > 0 ? { ...params, id: id } : { id: id }
        const response = await config.fn(data);
        handleResponse(response)
        if(response?.Status ==='success' && !pageWebsocketsEnabled){
            setLastAPIActionTime(new Date())
        }
        setConfirmDisabled(false)
        toggleModal({})
    };
    
    return visible ? 
        <SweetAlert disabled={confirmDisabled} title={config.title || 'ޔަޤީން؟'} confirmBtnText={config.confirmText || "އޯކޭ"} cancelBtnText='ކެންސަލް' warning showCancel={!confirmDisabled} confirmBtnBsStyle='success' cancelBtnBsStyle='danger' onConfirm={() => confirm()} onCancel={() => toggleModal({})} >
            <Loader loading={confirmDisabled} transparent />
            <span className="dv">{config.body || "މިބަދަލު ކުރީގަ ހުރި ގޮތަކައް ނުގެނެވޭނޭ!"}</span>
        </SweetAlert>
     : null
     
}