import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getUsedCaseListRequest } from "../../../api/controller/PhraseController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const phraseListState = atom({
    key: 'phraseListState',
    default: [],
})
export const phraseUsedCaseState = atom({
	key: 'phraseUsedCaseState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: true }

export const phraseFilterState = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const phraseDataStructureState = selector({
    key: 'phraseDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'ދިވެހި ބަސް',
                name: 'DhivehiPhrase',
                field: 'dhivehi_phrase',
                type: 'text',
                order: { table: 1, form: 1 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'އިނގިރޭސި ބަސް',
                name: 'EnglishPhrase',
                field: 'english_phrase',
                type: 'text',
                order: { table: 2, form: 2 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޢަރަބި ބަސް',
                name: 'ArabicPhrase',
                field: 'arabic_phrase',
                type: 'text',
                order: { table: 3, form: 3 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޕަބްލިޝް ކުރެވިފައި؟',
                name: 'Published',
                field: 'published',
                type: 'switch',
                order: { form: 4 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'ކުރު މާނަ',
                name: 'ShortMeaning',
                field: 'short_meaning',
                type: 'textarea',
                order: { form: 5 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ބޭނުންކޮށްފައިވާ ޤަޒިއްޔާތައް',
                name: 'UsedCases',
                field: 'used_cases',
                type: 'multiselect',
                order: { form: 6 }, 
                section: { id: 1, label: 'ޖެނެރަލް' },
                subFields: { label: 'number', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'enf' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(phraseUsedCaseState),
				getRequest: getUsedCaseListRequest,
            },
			{ 
                label: 'މާނަ',
                name: 'Meaning',
                field: 'meaning',
                type: 'tinymce',
                order: { form: 7 }, 
                section: { id: 2, label: 'ތަފްސީލު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
