import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import Register from "../app/guest/Register";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import CaseViewer from "../app/auth/CaseViewer";
import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import SubscriptionConfigs from "../app/auth/subscriptionConfigs"
import VerticalNoSidebarLayout from "../components/VerticalNoSidebarLayout";
import Phrases from "../app/auth/phrases"
import Analysis from "../app/auth/analysis"
import Courts from "../app/auth/courts"
import Judges from "../app/auth/judges"
import CaseTypes from "../app/auth/caseTypes"
import CaseStatuses from "../app/auth/caseStatuses"
import RelationTypes from "../app/auth/relationTypes"
import CaseFiles from "../app/auth/caseFiles"
// import Converter from "../app/auth/Converter";



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Register',
        icon: 'bx bx-lock',
        link: '/register',
        component: Register,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Forgot Password',
        icon: 'bx bx-lock',
        link: '/forgot-password',
        component: ForgotPassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Change Password',
        icon: 'bx bx-lock',
        link: '/change-password',
        component: ChangePassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: '',
        icon: "bx bx-group",
        children: [
            {
                name: 'މެއިން',
                icon: "mdi mdi-file-document",
                children: [
                    {
                        name: 'ހޯމް',
                        title: 'Home',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/home',
                        defaultRoute: true,
                        component: Home,
                        middleware: 'auth',
                        permission: 'read-caseFiles',
                        children: [],
                    },
                    {
                        name: 'ހޯމް',
                        title: 'Home',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/home',
                        defaultRoute: true,
                        component: Home,
                        layout: VerticalNoSidebarLayout,
                        middleware: 'auth',
                        unpermission: 'read-caseFiles',
                        children: [],
                    },
                    {
                        name: 'ޤަޒިއްޔާ',
                        title: 'Case Viewer',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/case',
                        component: CaseViewer,
                        middleware: 'auth',
                        layout: NonAuthLayout,
                        hidden: true,
                        children: [],
                    },
                    // {
                    //     name: 'ކޮންވާޓާ',
                    //     title: 'Converter',
                    //     icon: 'mdi mdi-monitor-dashboard',
                    //     link: '/converter',
                    //     component: Converter,
                    //     middleware: 'auth',
                    //     children: [],
                    // },
                    { // Analysis Route Begin
                        name: 'ޤަޒިއްޔާތައް',
                        title: 'Case Files',
                        icon: 'mdi mdi-file-document',
                        link: '/caseFiles',
                        component: CaseFiles,
                        middleware: 'auth',
                        permission: 'read-caseFiles',
                        children: [],
                    }, // Analysis Route End
                    { // Phrase Route Begin
                        name: 'ބަސްފޮތް',
                        title: 'Phrases',
                        icon: 'mdi mdi-book-multiple',
                        link: '/phrases',
                        component: Phrases,
                        middleware: 'auth',
                        permission: 'read-phrases',
                        children: [],
                    }, // Phrase Route End
                    { // Analysis Route Begin
                        name: 'އެނޭލިސިސް',
                        title: 'Analysis',
                        icon: 'mdi mdi-sine-wave',
                        link: '/analysis',
                        component: Analysis,
                        middleware: 'auth',
                        permission: 'read-analysis',
                        children: [],
                    }, // Analysis Route End
                ]
            },
            
            {
                name: 'މާސްޓާ',
                icon: "mdi mdi-cog",
                children: [
                    { // Court Route Begin
                        name: 'ކޯޓްތައް',
                        title: 'Courts',
                        icon: 'mdi mdi-scale-balance',
                        link: '/courts',
                        component: Courts,
                        middleware: 'auth',
                        permission: 'read-courts',
                        children: [],
                    }, // Court Route End
                    { // Court Route Begin
                        name: 'ފަނޑިޔާރުންތައް',
                        title: 'Judges',
                        icon: 'mdi mdi-gavel',
                        link: '/judges',
                        component: Judges,
                        middleware: 'auth',
                        permission: 'read-judges',
                        children: [],
                    }, // Court Route End
                    { // Court Route Begin
                        name: 'ޤަޒިއްޔާގެ ބާވަތްތައް',
                        title: 'Case Types',
                        icon: 'mdi mdi-shape',
                        link: '/caseTypes',
                        component: CaseTypes,
                        middleware: 'auth',
                        permission: 'read-caseTypes',
                        children: [],
                    }, // Court Route End
                    { // Court Route Begin
                        name: 'އަސަރުކުރާ ބާވަތްތައް',
                        title: 'Relation Types',
                        icon: 'mdi mdi-alert',
                        link: '/relationTypes',
                        component: RelationTypes,
                        middleware: 'auth',
                        permission: 'read-relationTypes',
                        children: [],
                    }, // Court Route End
                    { // Court Route Begin
                        name: 'ސްޓޭޓަސްތައް',
                        title: 'Case Statuses',
                        icon: 'mdi mdi-state-machine',
                        link: '/caseStatuses',
                        component: CaseStatuses,
                        middleware: 'auth',
                        permission: 'read-caseStatuses',
                        children: [],
                    }, // Court Route End
                    { // SubscriptionConfig Route Begin
                        name: 'ސެޓިންގްސް',
                        title: 'Subscription Config',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/subscriptionConfigs',
                        component: SubscriptionConfigs,
                        middleware: 'auth',
                        permission: 'read-subscriptionConfigs',
                        children: [],
                    }, // SubscriptionConfig Route End
                ]
            },
	        // Navigations
            
            {
                name: 'އެކްސެސް',
                icon: "bx bx-group",
                children: [
                    {
                        name: 'ޔޫސާތައް',
                        title: 'Users',
                        icon: 'bx bx-group',
                        link: '/acl/users',
                        component: Users,
                        middleware: 'auth',
                        permission: 'read-users',
                        children: []
                    },
                    {
                        name: 'ރޯލްތައް',
                        title: 'Roles',
                        icon: 'mdi mdi-account-key-outline',
                        link: '/acl/roles',
                        component: Roles,
                        middleware: 'auth',
                        permission: 'read-roles',
                        children: []
                    },
                    {
                        name: 'ހުއްދަތައް',
                        title: 'Permissions',
                        icon: 'bx bx-lock-open-alt',
                        link: '/acl/permissions',
                        component: Permissions,
                        middleware: 'auth',
                        permission: 'read-permissions',
                        children: []
                    },
                ]
            },
        ]
    }
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
          let returnVal = null;
          if (nav.children.length > 0) {
            if (
              (isAuth &&
                nav.middleware === "auth" &&
                (!nav.permission || permissions.includes(nav.permission)) &&
                (!nav.unpermission || !permissions.includes(nav.unpermission))) ||
              (!isAuth && nav.middleware === "guest") ||
              !nav.middleware
            ) {
              let tempChildren = [];
              nav.children.forEach((child) => {
                let temp = parseModule(child);
                if (temp) {
                  tempChildren = [...tempChildren, temp];
                }
              });
              returnVal = { ...nav, children: tempChildren };
            }
          } else {
            if (
              (isAuth &&
                nav.middleware === "auth" &&
                (!nav.permission || permissions.includes(nav.permission)) &&
                (!nav.unpermission || !permissions.includes(nav.unpermission))) ||
              (!isAuth && nav.middleware === "guest") ||
              !nav.middleware
            ) {
              returnVal = nav;
            }
          }
          return returnVal;
        };
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
