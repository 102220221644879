
// import config from "../../config.json";
/* DISABLEBEGIN */
import Echo from 'laravel-echo'
// eslint-disable-next-line
import Pusher from 'pusher-js'
/* DISABLEEND */


const options = {
  broadcaster: 'pusher',
  key: process.env.REACT_APP_WEBSOCKETS_KEY,
  cluster: process.env.REACT_APP_WEBSOCKETS_CLUSTER,
  forceTLS: process.env.REACT_APP_WEBSOCKETS_TLS === 'true',
  wsHost: process.env.REACT_APP_WEBSOCKETS_HOST,
  wsPort: process.env.REACT_APP_WEBSOCKETS_PORT,
  disableStats: true,
  enabledTransports: ['ws','wss'],
  authEndpoint: process.env.REACT_APP_API_PUBLIC_URL + process.env.REACT_APP_WEBSOCKETS_AUTHENDPOINT, 
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      Accept: 'application/json',
    },
  },
};
let echo = null
if(process.env.REACT_APP_WEBSOCKETS_ENABLED === 'true'){
  /* DISABLEBEGIN */
  echo = new Echo(options)
  /* DISABLEEND */
  /* ENABLEBEGIN *
  echo = options
  /* ENABLEEND */
}
export default echo;


// echo.private(`App.User.${userId}`).notification((data) => {
//     console.log(data);
// });