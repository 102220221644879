import React, { useEffect, useState } from 'react';

import { Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
import profile from "../../assets/images/logo-dark.png";
import profileLight from "../../assets/images/logo-light-1.png";
import logo from "../../assets/images/logo.svg";
import bgImage from "../../assets/images/law-background.jpg"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { loginRequest, verifyLoginRequest } from '../../api/controller/AuthController'
import { passwordResetHashState, otpReferenceState } from '../../api/state/AuthState';
import { themeTypeState } from '../../state/GlobalState';
import { Loader } from '../../custom/components/Loader';

const Login = ({history,handleAuthResponse}) =>  {
    const [error, setError] = useRecoilState(loginErrorState)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')))
    const [rememberMe, setRememberMe] = useState(false)
    const setPasswordResetHash = useSetRecoilState(passwordResetHashState)
    const [otpReference, setOtpReference] = useRecoilState(otpReferenceState)
    const themeType = useRecoilValue(themeTypeState)
    const [form, setForm] = useState({
        email: '',
        password: '',
        OTP: '',
    })

    // handleValidSubmit
    const handleValidSubmit = async (event) => {
        event.preventDefault()
        setSubmitDisabled(true)
        let response = null
        if(otpReference === null){
            response = await loginRequest({email: user ? user.email : form.email, password: form.password})
        } else {
            response = await verifyLoginRequest({ reference: otpReference, otp: form.OTP })
        }
        
        if(response.decodedToken){
            handleAuthResponse(response, response.encodedToken, rememberMe, true)
        } else if(response.scp){
            localStorage.removeItem('authToken')
            setPasswordResetHash(response.hash)
            history.push('/change-password');
        } else if(response.otp){
            localStorage.removeItem('authToken')
            setOtpReference(response.Reference)
            setSubmitDisabled(false)
        } else if (response.message){
            setError(response.message)
            setSubmitDisabled(false)
        } else {
            setError(response.Message)
            setSubmitDisabled(false)
        }
    }

    useEffect(() => {
        setError("");
    }, [setError])

    return (
        <React.Fragment>
            {/* <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
            </div> */}
            
            <div><img src={bgImage} alt="bg-img" style={{ zIndex: "-10", position: "fixed", top:0, opacity: 0.8, objectFit: "cover", right:'-10px'}} /></div>
            <div className="account-pages my-5 pt-sm-5">
                <Loader loading={submitDisabled} transparent />
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8} lg={6} className='p-0'>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className={"p-4 " + (themeType === 'dark' ? 'text-white' : 'text-primary')}>
                                                <h5 className={(themeType === 'dark' ? 'text-white' : 'text-primary')}>ޝުނާ.ލޯ</h5>
                                                <p>{user ? "އަންލޮކް ކުރަން ޕާސްވޯޑް ޖައްސަވާ." : "ކުރިޔަށް ދިއުމަށް ލޮގިން ކުރައްވާ."}</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-center">
                                            <img width="90%" src={profile} alt="shuna.law" className="img-fluid dark-logo" />
                                            <img width="90%" src={profileLight} alt="shuna.law" className="img-fluid light-logo" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="34" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">

                                        <Form className="form-horizontal" onSubmit={handleValidSubmit}>

                                            {error ? <Alert className='dvl' color="danger">{error}</Alert> : null}
                                            {otpReference === null ? (
                                                <>
                                                    {user ? (
                                                        <div className="user-thumb text-center mb-4">
                                                            <img src={user.avatar} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail"/>
                                                            <h5 className={"font-size-15 mt-3 " + (themeType === 'dark' ? 'text-white' : '')}>{user.name}</h5>
                                                        </div>
                                                    ) : (
                                                        <FormGroup>
                                                            <Label>އީމެއިލް</Label>
                                                            <Input value={form.email} onChange={(e)=>setForm({...form, email: e.target.value})} name="email" label="Email" className="form-control enf" placeholder="Email" type="email" required />
                                                        </FormGroup>
                                                    )}

                                                    <FormGroup>
                                                        <Label>ޕާސްވޯޑް</Label>
                                                        <Input value={form.password} onChange={(e)=>setForm({...form, password: e.target.value})} name="password" label="Password" type="password" required placeholder="Password" />
                                                    </FormGroup>
                                                    {!user ? (
                                                        <div className="custom-control custom-checkbox" onClick={()=>setRememberMe(!rememberMe)}>
                                                            <Label className="custom-control-label" htmlFor="customControlInline">  &nbsp;ހަނދާން ކުރޭ</Label> &nbsp;
                                                            <Input type="checkbox" className="custom-control-input" onChange={()=>false} checked={rememberMe} />
                                                        </div>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <FormGroup>
                                                    <Label>OTP</Label>
                                                    <Input value={form.OTP} onChange={(e)=>setForm({...form, OTP: e.target.value})} name="OTP" label="We sent an OTP via text" className="form-control" placeholder="Enter OTP" type="number" required />
                                                </FormGroup>
                                            )}
                                            
                                            

                                            <div className="mt-4">
                                                <button disabled={submitDisabled} className="btn btn-primary btn-block waves-effect waves-light w-100" type="submit">{otpReference === null ? user ? "ލޮގިން" : "ލޮގިން" : "ވެރިފައި"}</button>
                                                {!otpReference ? (
                                                    <>
                                                        <p align="center" className='mt-3 mb-3'>- އެނޫނީ -</p>
                                                        <button disabled={submitDisabled} className="btn btn-secondary btn-block waves-effect waves-light w-100" type="button" onClick={()=>history.push("/register")}>ރަޖިސްޓާ</button>
                                                    </>
                                                ) : null}
                                            </div>

                                            <div className="mt-4 text-center">
                                                {otpReference === null ? <Link to="/forgot-password" className={(themeType === 'dark' ? 'text-white' : 'text-muted')}><i className="mdi mdi-lock mr-1"></i> ޕާސްވޯޑް ހަނދާން ނެތުނީ؟</Link>
                                                 : <p>Go back to{" "}<span style={{cursor: "pointer"}} className={"font-weight-medium " + (themeType === 'dark' ? 'text-white' : 'text-primary')} onClick={()=>{localStorage.removeItem('authUser');setUser(null);setOtpReference(null)}}>Login</span>{" "}</p> }
                                                
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            {user ? (
                                <div className="mt-5 text-center">
                                    <p className={(themeType === 'dark' ? 'text-white' : '')} ><span className='bg-span'>އަނބުރާ <Link onClick={()=>{localStorage.removeItem('authUser');setUser(null)}} to="/" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}> ލޮގިން ޕޭޖަށް؟ </Link> </span></p>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Login);
