
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/PermissionController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const permissionListState = atom({
    key: 'permissionListState',
    default: [],
})

export const modalConfigDefault = { size: "sm", wizard: false }

export const permissionFilterState = selector({
    key: 'permissionFilterState',
    get: () => [],
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const permissionDataStructureState = selector({
    key: 'permissionDataStructureState',
    get: ({ get }) => (
        [
            { 
                label: "ނަން",
                name: "Name",
                field: "name",
                type: "text",
                order: { table: 1, form: 1 },
                table: { editable: true },
                create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },
            { 
                label: "ގާޑް ނަން",
                name: "Guard Name",
                field: "guard_name",
                type: "text",
                rootValue: true,
                order: { table: 2, form: 2 },
                table: { editable: true },
                create: [{ enabled: true, size: 12, default: "web" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },
        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})