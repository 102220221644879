
import { decryptResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'g';

export const getMetaRequest =  async (data) => {
    return await http.post(`${group}/getMeta`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const searchRequest =  async (data) => {
    return await http.post(`${group}/search`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}
export const advancedSearchRequest =  async (data) => {
    return await http.post(`${group}/advancedSearch`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const findRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getRefByCNRequest =  async (data) => {
    return await http.post(`${group}/getRefByCN`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

