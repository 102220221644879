
import React, { useEffect, useState } from "react";
import { findRequest } from "../../api/controller/GuestController";
import { CaseContent } from "../../custom/components/CaseContent";
import { Loader } from "../../custom/components/Loader";

const CaseViewer = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        async function findCase() {
            setLoading(true)
            const params = new URLSearchParams(window.location.search)
            const ref = params.get('ref')
            const data = await findRequest({ Type: 'CaseFile', Reference: ref })
            if(data?.Status !== "error"){
                setData(Object.keys(data).length === 0 ? false : data)
            } else {
                setData(false)
            }
            setLoading(false)
        }
        findCase()
    }, [])

    return (
        <React.Fragment>
            <Loader loading={loading} />
                {data !== false ? (
                    <CaseContent data={data} referrer="standalone" />
                ) : (
                    <div className="text-center">
                        <h1>ޤަޒިއްޔާ ނުފެނުނު!</h1>
                    </div>
                )}
        </React.Fragment>
        
    )
    
}

export default CaseViewer;

