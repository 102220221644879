
import { decryptResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'caseFiles';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getCourtListRequest =  async (data) => {
	return await http.post(`${group}/getCourtList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getJudgeListRequest =  async (data) => {
	return await http.post(`${group}/getJudgeList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getCaseTypeListRequest =  async (data) => {
	return await http.post(`${group}/getCaseTypeList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getCaseStatusListRequest =  async (data) => {
	return await http.post(`${group}/getCaseStatusList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getRelationTypeListRequest =  async (data) => {
	return await http.post(`${group}/getRelationTypeList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}


