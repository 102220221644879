

export const addLtrMarkers = (str) => {
    str = str.replace(/\u200E/g, '');  // Remove any existing LTR markers
    let result = '';
    let ltrMarkerAdded = false;
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      const charCode = char.charCodeAt(0);
      // Define ranges for common LTR characters (basic Latin, extended Latin, digits, etc.)
      const isLTR = (charCode >= 65 && charCode <= 122) || (charCode >= 48 && charCode <= 57);
      if (isLTR) {
        // If we're entering an LTR context from an RTL one, insert the LTR marker
        if (!ltrMarkerAdded) {
          result += '\u200E';  // LTR Marker
          ltrMarkerAdded = true;
        }
        result += char;
      } else {
        ltrMarkerAdded = false;  // Reset the flag
        result += char;
      }
    }
    return result;
}

export const removeLtrMarkers = (str) => str.replace(/\u200E/g, '')

