import React, { useEffect, useMemo, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, CardBody, Col, Container, Row, Button, Label, CardText, CardTitle, ButtonGroup, Input, Modal } from 'reactstrap';
import notify from '../../../custom/helpers/Notify';
import '../../../pages/Tables/datatables.scss';
import { ActionButtons } from '../../../custom/components/ActionButtons';
import { ConfirmDialog } from '../../../custom/components/ConfirmDialog';
import { pageState, lastAPIActionTimeState, pageWebsocketsEnabledState, themeTypeState, leftSidebarTypeState, themeDirection } from '../../../state/GlobalState';
import { DynamicModal } from '../../../custom/components/DynamicModal';
import { cloneDeep, get,set } from 'lodash';
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import { getDataListRequest, updateDataRequest, isDisplayedRequest, getCourtListRequest, runAnalysisRequest, findDataRequest, cancelDataRequest } from '../../../api/controller/AnalysisController'; // CHANGE (controller name)
import { formModes, analysisFilterState, modalConfigDefault, analysisDataStructureState as dataStructureState, analysisListState as dataListState, analysisCourtState } from './state'; // CHANGE (dataList & structure stateName)
import { Loader } from '../../../custom/components/Loader';
import echo from '../../../api/core/echo';
import { handleSocket, handleGridSocket } from '../../../custom/helpers/HandleSocket';
import { AgGridReact } from "ag-grid-react"
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { handleResponse } from '../../../custom/helpers/HandleResponse';
import SelectEditor from "../../../custom/components/grid/SelectEditor"
import DefaultEditor from "../../../custom/components/grid/DefaultEditor"
import MaskEditor from "../../../custom/components/grid/MaskEditor"
import DateTimeEditor from "../../../custom/components/grid/DateTimeEditor"
import RadioEditor from "../../../custom/components/grid/RadioEditor"
import CheckboxEditor from "../../../custom/components/grid/CheckboxEditor"
import SwitchEditor from "../../../custom/components/grid/SwitchEditor"
import moment from "moment";
import useBreakpoint from '../../../custom/components/hooks/useBreakpoint';
import { isDhivehi } from '../../../custom/helpers/IsDhivehi';
import MceEditor from '../../../custom/components/form-elements/MceEditor';
import { addLtrMarkers, removeLtrMarkers } from '../../../custom/helpers/LanguageHelpers';

const Analysis = () => {
  
  const model = 'Analysis' // CHANGE
  const modelDv = 'އެނޭލިސިސް' // CHANGE
  const models = 'Analysis' // CHANGE
  
  // GET & SET SELECTS
  const selects = [
    { model: 'Court', getFn: getCourtListRequest, setFn: useSetRecoilState(analysisCourtState), ref: useRef([]), keys: { label: 'name', value: 'id' } },
		
    // { model: 'ModelName', getFn: getModelListRequest, setFn: useSetRecoilState(modelState), ref: useRef([]), keys: { label: 'name', value: 'id' } },
  ]

  const [partialUpdating, setPartialUpdating] = useState(false)
  const dir = useRecoilValue(themeDirection)

  const defaultColDef = {
    sortable: true,
    editable: true,
    valueSetter: async(e) => {
      setPartialUpdating(true)
      let tempRow = { id: e.data.id }
      let s = ['select','multiselect']
      dataStructure.filter(i=>i.table?.editable).forEach((input) => {        
        let v = e.newValue
        if((e.column.colDef?.cellEditorParams?.fieldName || e.column.colId) !== getFN(input)){
          if(Array.isArray(e.data[getFN(input)])){
            v = e.data[getFN(input)].map(i=>s.includes(input.type) ? (i[input.subFields?.value || "id"] || i) : i)
          } else if(s.includes(input.type)){
            v = e.data[getFN(input)][input.subFields?.value || "id"] || e.data[getFN(input)]
          } else if(input.type === 'datepicker' && input.table?.postDateFormat){
            v = moment(e.data[getFN(input)]).format(input.table?.postDateFormat)
          } else {
            v = e.data[getFN(input)]
          }
        } else {
          if(input.type === 'datepicker' && input.table?.postDateFormat){
            v = moment(v).format(input.table?.postDateFormat)
          }
        }
        tempRow = {...tempRow, [getPN(input)]: v }
      })
      const response = await updateDataRequest({...tempRow})
      handleResponse(response)
      setPartialUpdating(false)
    },
  }
  
  const [ovf, _setOvf] = useState(false);
  const ovfRef = useRef(ovf);
  const setOvf = data => {
    ovfRef.current = data;
    _setOvf(data);
  }
  const [dataList, setDataList] = useRecoilState(dataListState);
  const [dataCount, setDataCount] = useState(0);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridApi, _setGridApi] = useState(null);
  const gridApiRef = useRef(gridApi)
  const setGridApi = data => {
    gridApiRef.current = data;
    _setGridApi(data)
  }
  const [pageWebsocketsEnabled, setPageWebsocketsEnabled] = useRecoilState(pageWebsocketsEnabledState)
  const setPage = useSetRecoilState(pageState)
  const [lastAPIActionTime, setLastAPIActionTime] = useRecoilState(lastAPIActionTimeState)
  const [tableLoading, setTableLoading] = useState(true)
  const [findLoading, setFindLoading] = useState(false)
  const themeType = useRecoilValue(themeTypeState)
  const [mobileSearchFilter, setMobileSearchFilter] = useState('')	
  const [searchValue, setSearchValue] = useState('')	
  const searchValueRef = useRef(searchValue)
	
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmID, setConfirmID] = useState(null);
  const [confirmParams, setConfirmParams] = useState({});
  const [confirmConfig, setConfirmConfig] = useState({ fn: null, title: null, body: null });
  const [cuModalConfig, _setCUModalConfig] = useState({visible: false, header: modelDv, type: null, typeKey: null, data: {}, ...modalConfigDefault})
  const cuModalConfigRef = useRef(cuModalConfig)
  const setCUModalConfig = data => {
    cuModalConfigRef.current = data;
    _setCUModalConfig(data)
  }
  const [actionsDisabled, setActionsDisabled] = useState(true)
  const [primaryChannel, setPrimaryChannel] = useState(null)
  const [filters, setFilters] = useRecoilState(analysisFilterState)
  const dataStructure = useRecoilValue(dataStructureState)
  const [selectedRow, setSelectedRow] = useState(null)
  
  const breakPoint = useBreakpoint()
  const [xsFData, _setXsFData] = useState([])
  const xsFDataRef = useRef(xsFData)
  const setXsFData = data => {
    xsFDataRef.current = data;
    _setXsFData(data)
  }
  const [xsPageSize] = useState(10)
  const [xsCurrentPage, setXsCurrentPage] = useState(1)
  const [xsMaxPage, setXsMaxPage] = useState(1)

  const leftSidebarType = useRecoilValue(leftSidebarTypeState)
  // const [quillParser, setQuillParser] = useState(null)

  useEffect(() => {
    if(gridApi){
      gridApi.sizeColumnsToFit()
    }
    // eslint-disable-next-line
  }, [leftSidebarType])

  useEffect(() => {
    setPage((models.charAt(0).toLowerCase() + models.slice(1)).split(" ").join(""))
    setPageWebsocketsEnabled(process.env.REACT_APP_WEBSOCKETS_ENABLED === 'true')
  }, [setPage, setPageWebsocketsEnabled])

  useEffect(() => {
    async function fetchData(filter){
      setTableLoading(true)
      setDataList([])
      const response = await getDataListRequest(filter);
      if(response && response.Status !== 'error'){
        setDataList(response.data)
        response.ovf ? setOvf(true) : setOvf(false)
        response.count ? setDataCount(response.count) : setDataCount(response.data?.length)
      } else {
        notify({ status: 'error', message: response.Message })
      }	
      searchValueRef.current = searchValue;	
      setTableLoading(false)
    }
    let fetch = true
    let postData = {}
    if(filters.length > 0){
      filters.forEach((filter)=>{
        fetch = filter.value.length === 0 && filter.required ? false : fetch
        if(filter.value){
          set(postData, filter.name || filter.label.replace(/ /g, ''), filter.type === "select" ? filter.value.value : filter.value)
        }
      })	
    }	
    if(ovf){	
      set(postData, 'SearchQuery', removeLtrMarkers(searchValue))
    }	
    if(fetch){	
      fetchData(postData)	
    }
    if(pageWebsocketsEnabled){
      const channelFilters = Object.keys(postData).filter((k)=>k !== "SearchQuery").length > 0 ? "." + Object.keys(postData).filter((k)=>k !== "SearchQuery").map((val)=>(postData[val].toString())).join(".") : ""
      const channelToSub = `${model.replace(/ /g, '')}-Primary${channelFilters}`
      if(primaryChannel !== channelToSub){
        if(primaryChannel){
          echo.leave(primaryChannel)
        }
        setPrimaryChannel(channelToSub)
        echo.private(channelToSub).listen(`.${model.replace(/ /g, '')}Events`, (data) => {
          if(gridApiRef.current){
            handleGridSocket(data, gridApiRef.current, setGridApi, (ovfRef.current ? isDisplayedRequest : false), searchValueRef.current)
          } else {
            handleSocket(xsFDataRef.current, data, setXsFData)
          }
          if(data.from !== echo.socketId() && cuModalConfigRef.current.visible === true && cuModalConfigRef.current.type === "Update" && parseInt(cuModalConfigRef.current.data.id) === parseInt(data.data.id) && data.event === "Updated"){
            setCUModalConfig({...cuModalConfigRef.current, expired: true})
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [lastAPIActionTime, filters, pageWebsocketsEnabled]);

  useEffect(() => {
    async function fetchSelectData(){
      setActionsDisabled(true)
      await Promise.all(selects.map(async(select)=>{
        if(! (select.persist && select.read?.length > 0)){
          const response = await select.getFn();
          const setFnRef = data => {
            select.ref.current = data
            select.setFn(data)
          }
          if(select.wsEnabled){
            echo.private(`${select.model}-Select`).listen(`.${select.model}Events`, (data) => {
              handleSocket(select.ref.current, data, setFnRef, select.keys || { label: "name", value: "id" })
            });
          }
          if(response && response.Status !== 'error'){	
            setFnRef({ ovf: response.ovf, data: (select.exactValue ? response.data : response.data?.map((val)=>({ label: val[select.keys.label || "name"], value: val[select.keys.value || "id"] }))) })	
          } else {	
            notify({ status: 'error', message: response.Message });	
          }
        }
      }))
      setActionsDisabled(false)
    }
    fetchSelectData();
    return () => {
      setDataList([])
      if(pageWebsocketsEnabled){
        echo.leave(primaryChannel)
      }
      selects.filter(s=>!(s.persist && s.read?.length > 0)).forEach((s)=>{
        s.ref.current = []
        s.setFn([])
        if(s.wsEnabled){
          echo.leave(`${s.model}-Select`)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const findRow = async(row, findFn) => {
    setFindLoading(true)
    const response = await findFn({id: row.id})
    if(response && response.data){
      row = response.data
      row.___complete___ = true
      if(gridApiRef.current){
        gridApiRef.current.getRowNode(row.id).setData(row)
        if(gridApiRef.current && gridApiRef.current.getSelectedNodes() && gridApiRef.current.getSelectedNodes()[0]){
            gridApiRef.current.getSelectedNodes()[0].setSelected(false)
            setTimeout(()=>{
                gridApiRef.current.getRowNode(row.id).setSelected(true)
            }, [50])
        }
      } else {
        setXsFData(xsFDataRef.current.map((val)=>row.id === val.id ? row : val))
      }
    } else {
      handleResponse(response)
      row = false
    }
    setTimeout(() => setFindLoading(false), 250)
    return row
  }
  
  const toggleCUModal = async({row={}, find=false, mode=null, modeKey=0, size=null, wizard=null, fullscreen=false}) => {
    if(find && !row.___complete___){
      row = await findRow(row, find)
    }
    if(row){
      setCUModalConfig({ ...cuModalConfig, fullscreen: fullscreen, visible: !cuModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row, size: size || cuModalConfig.size, wizard: wizard !== null ? wizard : cuModalConfig.wizard })
    }
  }

  const toggleConfirmModal = ({row={}, params={}, request=null, title=null, body=null}) => {
    setConfirmID(row ? row.id : null)
    setConfirmParams(params)
    setConfirmConfig({ fn: request, title: title, body: body })
    setConfirmModalVisible(! confirmModalVisible)
  }

  const toggleConfirmAnalysis = ({row={}, params={}, request=null, title=null, body=null}) => {
    setConfirmID(row)
    setConfirmParams(params)
    setConfirmConfig({ fn: request, title: title, body: body })
    setConfirmModalVisible(! confirmModalVisible)
  }
  const getPN = (val) => {
    return val.name || val.label.replace(/ /g, '')
  }
  
  const getFN = (val) => {
    return val.field || val.label.replace(/ /g, '_').toLowerCase()
  }

  // const [conversion, setConversion] = useState(null)

  const fnAnalyze = async(row) => {
    setFindLoading(true)
    let data = row.id
    const response = await runAnalysisRequest({id: data.id})
    if(response && response.Status === 'success'){
      notify({ status: 'success', message: response.Message });
    } else {
      notify({ status: 'error', message: response.Message });
    }
    setFindLoading(false)
  }

  const [reviewModalVisible, setReviewModalVisible] = useState(false)
  const [reviewModalData, setReviewModalData] = useState(null)
  const toggleReviewModal = async({row}) => {
    if(row){
      if(! row.content){
        row = await findRow(row, findDataRequest)
      }
      setReviewModalData(row)
    } else {
      setReviewModalData(null)
    }
    setReviewModalVisible(! reviewModalVisible)
  }

  const downloadFile = ({row}) => {
    const url = (process.env.REACT_APP_STORAGE_TYPE === 'external' ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL) + row.pdf_file
    window.open(url, '_blank')
  }


  const actionListLocation = 'inline' // inline
  const actionListType = 'menu' // menu
  const xsActionListType = 'menu' // menu
  const xsActions = useMemo(() => ([
    {
      label: 'ހުޅުވާ',
      permission: 'read',
      icon: 'mdi mdi-eye',
      color: 'primary',
      fn: toggleCUModal,
      params: { mode: "View", size: "xl", find: findDataRequest }
    },
    {
      label: 'ރިވިއު',
      permission: 'read',
      icon: 'mdi mdi-file-document-outline',
      color: 'info',
      fn: toggleReviewModal,
      params: {  }
    },
    {
      label: 'އަޕްޑޭޓް',
      permission: 'update',
      icon: 'mdi mdi-pencil',
      color: 'success',
      fn: toggleCUModal,
      params: { mode: "Update", size: "xl", wizard: true, fullscreen: true, find: findDataRequest }
    },
    {
      label: 'އެނެލައިޒް',
      permission: 'analyze',
      icon: 'mdi mdi-run',
      color: 'warning',
      fn: toggleConfirmAnalysis,
      params: { request: fnAnalyze }
    },
    {
      label: 'ރީއެނެލައިޒް',
      permission: 'reanalyze',
      icon: 'mdi mdi-run',
      color: 'warning',
      fn: toggleConfirmAnalysis,
      params: { request: fnAnalyze }
    },
    {
      label: 'ޑައުންލޯޑް',
      permission: 'read',
      icon: 'mdi mdi-download',
      color: 'warning',
      fn: downloadFile,
    },
    {
      label: 'ކެންސަލް',
      permission: 'cancel',
      icon: 'mdi mdi-trash-can',
      color: 'danger',
      fn: toggleConfirmModal,
      params: { request: cancelDataRequest }
    },
    // eslint-disable-next-line
  ]), []);

  // eslint-disable-next-line
  const actions = useMemo(() => xsActions, [xsActions])
  
  const renderActions = (row, xs=false, actionList=false) => <ActionButtons key={row.id} actions={(actionList ? actionList : actions).filter(a=>((a.label !== "ޑައުންލޯޑް" || row.pdf_file) && (a.label !== "ކެންސަލް" || (row.status !== "Cancelled" && row.status !== "Finalized")) && ((a.label !== "އަޕްޑޭޓް" || (row.status !== "Finalized"))) && (a.label !== "އެނެލައިޒް" || (row.status === "Pending")) && (a.label !== "ރީއެނެލައިޒް" || (row.status === "Analyzed"))))} type={xs ? xsActionListType : actionListType} row={row} xs={xs} />
  
  const valueGetter = (data, input) => {
    let d = get(data, getFN(input))
    if(input.type === 'checkbox'){
      return (parseInt(d) === 1 || d === true) ? "Yes" : ((parseInt(d) === 0 || d === false) ? "No" : "")	
    } else if(input.type === 'switch'){
      return (parseInt(d) === 1 || d === true) ? (input.list && input.list[0] ? input.list[0] : "Yes") : ((parseInt(d) === 0 || d === false) ? (input.list && input.list[1] ? input.list[1] : "No") : "")
    } else if((input.type === 'select' || input.type === 'multiselect' || input.type === 'nestedselect') && !input.rootValue){
      return d ? d[input.subFields?.label || "name"] : ""
    } else if(input.type === 'datepicker' && input.table?.dateFormat){
      return d ? moment(d).format(input.table.dateFormat) : null
    } else {
      return d
    }
  }

  const cellEditor = (type) => {
    switch(type){
      case 'checkbox':
        return 'CheckboxEditor'
      case 'switch':
        return 'SwitchEditor'
      case 'datepicker':
        return 'DateTimeEditor'
      case 'select':
        return 'SelectEditor'
      case 'multiselect':
        return 'SelectEditor'
      case 'mask':
        return 'MaskEditor'
      case 'radio':
        return 'RadioEditor'
      default:
        return 'DefaultEditor'
    }
  }

  // Default Selected Values as of now are set in Cell Editors by searching via label displayed on table
  let columns = useMemo(() => {
    let temp = [...dataStructure.filter((val) => val.order?.table).sort((a,b)=>(a.order?.table > b.order?.table ? 1 : -1)).map((val) => (
        {
          headerName: val.tableHeader || val.label, 
          headerClass: (val.table?.dv || isDhivehi(val.tableHeader || val.label)) ? 'dvl' : '',
          field: ((val.field || val.label.replace(/ /g, '_').toLowerCase()).replace(/\./g, '') + (val.subFields || (val.type === "select" && !val.rootValue) ? val.subFields?.label || val.subFields?.name || "name" : "" )).replace(/\./g, ''),
          filter: val.type === 'number' ? 'agNumberColumnFilter' : (val.type === 'datepicker' ? 'agDateColumnFilter' : 'agTextColumnFilter'),
          editable: val.table?.editable || false,
          cellEditor: cellEditor(val.type),
          cellEditorPopup: val.type === 'checkbox' || val.type === 'select' || val.type === 'multiselect' || val.type === 'radio',
          valueGetter: (params) => valueGetter(params?.data || params, val),
          cellRendererFramework: (params) => val.table?.customRender ? val.table.customRender(valueGetter(params?.data || params, val), params) : valueGetter(params?.data || params, val),
          cellEditorParams: (params) => ({
            fieldName: getFN(val),
            values: val.type === 'datepicker' ? {
                options: val.table?.options,
                value: params.data[getFN(val)]
              } : val.type === 'select' || val.type === 'multiselect' || val.type === 'radio' ? {
                list: val.list,
                getRequest: val.getRequest,
                creatable: ((val.type === 'select' || val.type === 'multiselect') && val.table?.creatable) || false,
                type: val.type,
                rootValue: val.rootValue,
                subFields: val.subFields,
              } : val.type === 'mask' ? {
                mask: val.table?.mask
              } : val.type === 'switch' ? {
                list: val.list,
              } : {
                type: val.type
              }
            
          }),
          maxWidth: val.table?.maxWidth || null,
          minWidth: val.table?.minWidth || null,
          type: dir === 'rtl' ? 'rightAligned' : '',
          cellStyle: (params) => val.table?.dv || isDhivehi(valueGetter(params?.data || params, val)) ? {fontFamily: 'faruma', fontSize: '1.35em', direction: 'rtl', lineHeight: '1.5em'} : {},
          wrapText: val.table?.wrapText || false
        }
      )),
      actionListLocation === 'inline' && !(breakPoint === 'xs' || breakPoint === 'sm') ? ({
        headerName: "އެކްޝަން ތައް",
        headerClass: 'dvl',
        valueGetter: () => null,
        cellRendererFramework: (params) => renderActions(params?.data || params),
        maxWidth: 170,
        type: dir === 'rtl' ? 'rightAligned' : '',
        cellStyle: () => dir === 'rtl' ? {fontFamily: 'faruma', fontSize: '1em', direction: 'rtl'} : {}
      }) : null
      
    ].filter(i=>i!==null)
    if(dir === 'rtl'){
      temp = temp.reverse()
    }
    return temp
    // eslint-disable-next-line
  }, [dataStructure])

  useEffect(() => {
    if(gridApi){
      gridApi.sizeColumnsToFit()
    }
    // eslint-disable-next-line
  }, [columns])

  const data = dataList?.map((row) => {
    let tempRow = cloneDeep(row)
    dataStructure.filter((input) => input.order?.table).forEach((input) => {
      const field = input.subFields || (input.type === "select" && !input.rootValue) ? (input.subFields?.label || input.subFields?.name || input.type === "select" ? ((input.field || input.label.replace(/ /g, '_').toLowerCase()) + "." + (input.subFields?.label || input.subFields?.name || "name")) : (input.field || input.label.replace(/ /g, '_').toLowerCase())) : (input.field || input.label.replace(/ /g, '_').toLowerCase());
      let temp = get(tempRow,field)
      if(! (temp === false || temp === 0)){
        temp = temp || input.tableDefault || ""
      }
      set(tempRow, field.replace(/\./g, ''), temp)
    })
    return tempRow
  })

  // specify the data
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if(params.api){
      params.api.sizeColumnsToFit();
      window.addEventListener('resize', function () {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        });
      });
      params.api.sizeColumnsToFit();
    }
  };

  useEffect(() => {	
    if(! ovf){	
      setXsFData(data.filter(item=>{	
        let match = true	
        if(mobileSearchFilter.length > 0){	
          let filters = mobileSearchFilter.split(" ")	
          filters.filter(f=>f.length > 0).forEach(filter=>{	
            let innerMatch = false	
            columns.forEach((column)=>{	
              if(String(column.valueGetter(item)).toLowerCase().indexOf(String(filter).toLowerCase()) >= 0){	
                innerMatch = true	
              }	
            })	
            if(! innerMatch){	
              match = false	
            }	
          })	
        }	
        return match	
      }))	
    } else {	
      setXsFData(data)	
    }	
    // eslint-disable-next-line	
  }, [dataList, mobileSearchFilter])	
  useEffect(() => {	
    if(! ovf){	
      setSearchValue(mobileSearchFilter)	
    }	
    // eslint-disable-next-line
  }, [mobileSearchFilter])

  useEffect(()=>{
    let maxPages = Math.ceil(xsFData.length / xsPageSize)
    if(maxPages !== xsMaxPage){
      setXsCurrentPage(1)
    }
    setXsMaxPage(maxPages)
    // eslint-disable-next-line
  }, [xsFData])


  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Loader loading={partialUpdating} transparent />
          <Row>
            <Col xs={12} md={4}>
              <h3>
                {modelDv}{" "}
                </h3>
            </Col>
            <Col xs={12} md={8} align="left">
              {filters && filters.length > 0 ? (
                <Row>
                  {filters.map((filter, key)=>(
                    <Col key={key} xs={12} md={filter.size || 6} className={filter.offset ? `offset-md-${filter.offset}` : ''}>
                      <Row>
                        <Col className="d-none d-md-block mt-2" xs={12} md={3} align={dir === "rtl" ? "left" : "right"}>
                          <Label>{filter.displayLabel || filter.label}</Label>
                        </Col>
                        <Col className="d-block d-md-none mt-2" xs={12} md={3} align={dir === "rtl" ? "right" : "left"}>
                          <Label>{filter.displayLabel || filter.label}</Label>
                        </Col>
                        <Col xs={12} md={9} align={dir === "rtl" ? "right" : "left"}>
                        {filter.type === "date" ? (
                            <Flatpickr onChange={(_,e) => setFilters({filterLabel: filter.label, value: e})} placeholder="Select..." options={{ altInput: true, defaultDate: filter.value }} value={filter.value} className="form-control d-block" />
                          ) : null}
                          {filter.type === "dateRange" ? (
                            <Flatpickr onChange={(_,e) => (e.split(" to ").length > 1) ? setFilters({filterLabel: filter.label, value: (e.split(" to "))}) : false} placeholder="Select..." options={{ mode:'range', altInput: true, defaultDate: filter.value }} value={filter.value} className="form-control d-block" />
                          ) : null}
                          {filter.type === "select" ? (
                            <Select onChange={(e) => setFilters({filterLabel: filter.label, value: e})} placeholder="Select..." className={filter.dv ? "dv" : ""} options={filter.list?.data} value={filter.value} />
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  ))}                  
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              {!(breakPoint === 'xs' || breakPoint === 'sm') ? (
                <Card className='d-none d-md-block'>
                  <CardBody>
                    <Loader loading={tableLoading} />
                    <Loader loading={findLoading} transparent />
                    <Row>
                      <Col xs={6}>
                        <div style={{display: 'flex', justifyContent: dir === "rtl" ? "right" : "left"}}>	
                          <input value={addLtrMarkers(searchValue)} onKeyUp={(e)=>{	
                            if(e.key === 'Enter' && ovf){	
                              setLastAPIActionTime(new Date())	
                            }	
                          }} type="text" className="form-control dv" onChange={(e)=>{	
                            if(!ovf){	
                              gridApi.setQuickFilter(removeLtrMarkers(e.target.value));	
                              setGridApi(gridApi)	
                            }	
                            setSearchValue(e.target.value)	
                          }} placeholder="ހޯދާ..." style={{ maxWidth:"400px", marginBottom:"15px", ...(searchValue.length === 0 || isDhivehi(searchValue[0]) ? {fontFamily: 'faruma', direction: 'rtl', fontSize: '1.2em'} : {}) }} />	
                          {ovf ? (	
                            <>&nbsp;&nbsp;&nbsp;<Button style={{maxHeight:'38px'}} color='primary' className='btn-sm waves-effect waves-light' onClick={()=>setLastAPIActionTime(new Date())}>ހޯދާ <i className='mdi mdi-magnify'></i></Button></>	
                          ) : null}	
                          {dataCount ? (
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='dv mt-2' style={{fontSize:'1.3em'}}>ޖުމްލަ: {dataCount}</span></>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div style={{display: 'flex', justifyContent: dir === "rtl" ? "left" : "right"}}>
                          {selectedRow && actionListLocation !== 'inline' ? (
                            <React.Fragment>
                              {renderActions(selectedRow?.data)}&nbsp;&nbsp;
                              <Button disabled={actionsDisabled} color='info' className='btn-sm waves-effect waves-light' style={{marginLeft:'5px'}} type='submit' onClick={()=>{
                                if(gridApi.getSelectedNodes().length > 0){
                                  gridApi.getSelectedNodes()[0].setSelected(false)
                                }
                              }}>
                                <i className='mdi mdi-close d-block font-size-12'></i>
                              </Button>
                            </React.Fragment>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className={themeType === 'dark' ? "ag-theme-alpine-dark" : "ag-theme-alpine"} style={{height: '650px'}}>
                        <AgGridReact
                            frameworkComponents={{
                              renderActions: renderActions,
                              SelectEditor: SelectEditor,
                              DefaultEditor: DefaultEditor,
                              MaskEditor: MaskEditor,
                              DateTimeEditor: DateTimeEditor,
                              RadioEditor: RadioEditor,
                              CheckboxEditor: CheckboxEditor,
                              SwitchEditor: SwitchEditor,
                            }}
                            // editType="fullRow"
                            onSelectionChanged={()=>{
                              setSelectedRow(gridApi.getSelectedNodes()[0] || false)
                            }}
                            enableCellTextSelection={true}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            rowHeight={55}
                            rowStyle={{border: 'none'}}
                            animateRows={true}
                            pagination={true}
                            paginationPageSize={10}
                            rowData={data}
                            getRowNodeId={(data)=>data.id}
                            columnDefs={columns}
                            rowSelection="single"
                          >
                        </AgGridReact>
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <div className="d-block d-md-none mt-2">
                  <Loader loading={tableLoading} />
                  <Loader loading={findLoading} transparent />
                  <div className="d-block d-md-none" style={{display: 'flex', justifyContent: 'left'}}>	
                    <div style={{display: 'flex', justifyContent: 'left'}}>	
                      <input value={addLtrMarkers(mobileSearchFilter)} type="text" className="form-control dv" onKeyUp={(e)=>{	
                          if(e.key === 'Enter' && ovf){	
                            setLastAPIActionTime(new Date())	
                          }	
                        }} onChange={(e)=>{	
                          setMobileSearchFilter(removeLtrMarkers(e.target.value))
                        }} placeholder="ހޯދާ..." style={{ maxWidth: ovf ? "315px" : "100%", marginBottom:"15px", ...(mobileSearchFilter.length === 0 || isDhivehi(mobileSearchFilter[0]) ? {fontFamily: 'faruma', direction: 'rtl', fontSize: '1.2em'} : {}) }} />	
                        {ovf ? (	
                          <>&nbsp;&nbsp;&nbsp;<Button style={{maxHeight:'38px', minWidth:'58px'}} color='primary' className='btn-sm waves-effect waves-light' onClick={()=>setLastAPIActionTime(new Date())}>Search</Button></>	
                        ) : null}	
                    </div>	
                  </div>
                  {xsFData.slice(((xsCurrentPage-1) * xsPageSize), (xsCurrentPage * xsPageSize)).map((item, key)=>(
                    <Card key={key} className='border border-primary'>
                      <CardTitle className='text-center bg-primary-light pt-2 pb-2 text-white enf'>{item?.judgement_number}</CardTitle>
                        <CardBody className='pt-0 pb-0'>
                          {cloneDeep(columns).reverse().map((column, ck) => (
                            column.field !== "judgement_number" ? <CardText key={ck} className="mb-2"><span style={{fontWeight:600}}>{column.headerName}: </span><span className={themeType === 'dark' ? 'text-white' : 'text-primary'}>{column.valueGetter(item)}</span></CardText> : null
                          ))}
                        </CardBody>
                      <ButtonGroup>
                        {dataList.length > 0 ? renderActions(item, true, xsActions) : null}
                      </ButtonGroup>
                    </Card>
                  ))}
                  <ButtonGroup className='mb-3' style={{display:"flex"}}>
                    <Button disabled={xsCurrentPage <= 1} onClick={()=>setXsCurrentPage(1)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                      <i className='mdi mdi-chevron-double-left d-block font-size-16'></i>
                    </Button>
                    <Button disabled={xsCurrentPage <= 1} onClick={()=>setXsCurrentPage((c)=>--c)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                      <i className='mdi mdi-chevron-left d-block font-size-16'></i>
                    </Button>
                    <Button color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                      <Input style={{maxWidth:"50px"}} type="text" value={xsCurrentPage} onClick={(e)=>e.target.setSelectionRange(0, e.target.value.length)} onChange={(e)=>{
                        if(parseInt(e.target.value) > 0 && parseInt(e.target.value) <= xsMaxPage){
                          e.target.blur()
                          setXsCurrentPage(parseInt(e.target.value))
                        }
                      }} />
                    </Button>
                    <Button color='primary' className='btn-md waves-effect waves-light'>
                      of
                    </Button>
                    <Button color='primary' className='btn-md waves-effect waves-light'>
                      {xsMaxPage}
                    </Button>

                    <Button disabled={xsCurrentPage >= xsMaxPage} onClick={()=>setXsCurrentPage((c)=>++c)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                      <i className='mdi mdi-chevron-right d-block font-size-16'></i>
                    </Button>
                    <Button disabled={xsCurrentPage >= xsMaxPage} onClick={()=>setXsCurrentPage(xsMaxPage)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                      <i className='mdi mdi-chevron-double-right d-block font-size-16'></i>
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </Col>
          </Row>

          <DynamicModal config={cuModalConfig} toggleModal={toggleCUModal} submitModes={formModes} ds={dataStructure} />

          <ConfirmDialog id={confirmID} params={confirmParams} config={confirmConfig} visible={confirmModalVisible} toggleModal={toggleConfirmModal} />
          
          {/* <div id="quillParser" style={{display: 'none'}}> */}
            {/* <QuillEditor value={quillParser} onChange={(e)=>setQuillParser(e)} conversion={conversion} /> */}
          {/* </div> */}

          
          <Modal size="xl" isOpen={reviewModalVisible} toggle={()=>toggleReviewModal({})} fullscreen>
              <div className="modal-header" style={{backgroundColor:"rgba(0,0,0,0.07)"}}>
                  <h5 className="modal-title mt-0" id="myModalLabel">{reviewModalData?.title || "ރިވިއު"}</h5>
                  <button type="button" onClick={() => toggleReviewModal({})} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <React.Fragment>
                  <div id="modalBody" className="modal-body">
                      <MceEditor value={reviewModalData?.content} readOnly={true} height={"80vh"} />
                  </div>
                  <div className="modal-footer" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
                      <button type="button" onClick={() => toggleReviewModal({})} className="btn btn-secondary waves-effect">ކްލޯސް</button>
                  </div>
              </React.Fragment>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Analysis);
