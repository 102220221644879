
import { Card, CardBody, CardText, CardTitle, Col, DropdownItem, Modal, Row } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { Menu } from '@material-ui/core';
import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.snow.css';
import { handleResponse } from "../../helpers/HandleResponse";
import notify from "../../helpers/Notify";
import Select from "react-select";


// Quill.register(class csrefNo extends Quill.import('formats/bold') { static blotName = 'csref-no'; static tagName = 'span'; static className = 'csref-no'; });
const Inline = Quill.import('blots/inline');
Quill.register('formats/inline-direction', class Variable extends Inline {
    static blotName = 'inline-direction';
    static tagName = 'span';
  
    static create(value) {
        let node = super.create();
        node.classList.add("inDir-" + value);
        return node;
    }
    static formats = (node) => {
        const formats = [];
        node.classList.forEach(className => {
            if (className.startsWith("inDir-")) {
                formats.push(className.replace("inDir-", ""));
            }
        });
        return formats.length > 0 ? formats[0] : true;
    };
});
Quill.register('formats/highlight', class Variable extends Inline {
    static blotName = 'highlight';
    static tagName = 'span';
    static className = 'highlight'
  
    static create(value) {
        let node = super.create();
        node.classList.add("highlight-" + value);
        return node;
    }
    static formats(node) {
        const formats = [];
        node.classList.forEach(className => {
            if (className.startsWith("highlight-")) {
                formats.push(className.replace("highlight-", ""));
            }
        });
        return formats.length > 0 ? formats[0] : true;
    }
});
Quill.register('formats/searchlight', class Variable extends Inline { 
    static blotName = 'searchlight';
    static tagName = 'span';
    static className = 'searchlight';
    static create() {
        let node = super.create();
        node.classList.add('searchlight');
        return node;
    }
    // static formats = () => true;
    static formats(node) {
        if (node.classList && node.classList.contains('searchlight')) {
            return true;
        }
        return false;
    }
});
class FootnoteBlot extends Inline {  
    static blotName = 'footnote';
    static tagName = 'sup';
    static className = 'ftnte';
    static create(id) {
        let node = super.create(id);
        node.setAttribute('data-xid', id);
        // node.innerHTML = "&#xFEFF;"
        node.setAttribute('contenteditable', false);
        node.classList.add('ftnte');
        return node;
    }

    static formats(node) {
        return node.getAttribute('data-xid');
    }
}
Quill.register(FootnoteBlot);


Quill.register('formats/csrefNo', class Variable extends Inline { 
    static blotName = 'csref-no';
    static tagName = 'span';
    static className = 'csref-no';
    static create() {
        let node = super.create();
        node.classList.add('csref-no');
        return node;
    }
    // static formats = () => true;
    static formats(node) {
        if (node.classList && node.classList.contains('csref-no')) {
            return true;
        }
        return false;
    }
});
class AnnotationBlot extends Inline {  
    static blotName = 'annotation';
    static tagName = 'span';
    static className = 'annotation';
    static create({ id, type }) {
        let node = super.create({ id, type});
        node.setAttribute('data-annid', id)
        node.classList.add('annotation')
        node.classList.add(`ann_${type}`)
        return node;
    }

    static formats(node) {
        const annotationClass = Array.from(node.classList).find(className => className.startsWith('ann_'));
        const type = annotationClass ? annotationClass.replace('ann_', '') : null;
        return type && node.getAttribute('data-annid') ? {
            id: node.getAttribute('data-annid'),
            type: type
        } : false;
    }
}
Quill.register(AnnotationBlot);

var icons = Quill.import('ui/icons');
icons['csref-no'] = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="26.988" viewBox="0 0 28 26.988"> <g id="Layer_x0020_1" transform="translate(-1.27 -8.9)"> <path class="ql-fill" id="Path_5" data-name="Path 5" d="M24,22.479q2.1-.776,2.911-1.1a2.094,2.094,0,0,1,.637-.072.9.9,0,0,1,.3-.048,1.23,1.23,0,0,1,1.042.676,2.222,2.222,0,0,1,.374,1.241,1.264,1.264,0,0,1-.716,1.209l-1.678.652c-.58.215-1.439.533-2.593.954-.6.215-.907.39-.907.509a.208.208,0,0,0,.024.1.627.627,0,0,0-.072.143c0,.119.373.509,1.121,1.161a3.15,3.15,0,0,1,1.026,2.052,2.759,2.759,0,0,1-.048,1.169,5.115,5.115,0,0,1-2.076,2.7,7.749,7.749,0,0,1-3.166,1.352,34.358,34.358,0,0,1-5.241.676c-2.331.1-3.65.024-3.961-.207-.7-.295-1.05-1.185-1.05-2.656,0-.763.222-1.177.676-1.233a5.359,5.359,0,0,1,1.582.406,6.845,6.845,0,0,0,1.885.453,15.785,15.785,0,0,0,2.076-.119,15.772,15.772,0,0,0,2.8-.509c1.487-.342,2.235-.923,2.235-1.749a6.442,6.442,0,0,0-1.869-1.415,2.284,2.284,0,0,1-1.281-1.98q0-2.172,5.965-4.359ZM2.955,24.112l.971-3.505H1.27l.2-1.861H4.375L5.5,14.293H1.526l.2-1.834H6L6.928,8.9H9.171l-.93,3.559H13.48L14.5,8.9h2.243l-.989,3.559H18.4l-.2,1.834H15.322l-1.143,4.453h3.961l-.2,1.861H13.713l-.975,3.505H10.526l.944-3.505H6.2l-1,3.505Zm3.678-5.367H11.9l1.17-4.453h-5.3L6.634,18.745Z" fill="#373435"></path> </g> </svg>';
// fa asterik
icons['footnote'] = '<i class="fas fa-star-of-life fa-sm"></i>'
icons['clean'] = '<i class="fas fa-times fa-sm"></i>'
icons['annotation'] = '<i class="fas fa-comment-alt fa-sm"></i>'


const modules = {
    toolbar: {
        container: [
            [{'align': []}], // text alignment
            [{'direction': 'rtl'}], // text direction
            [{ 'header': [1, 2, false] }],
            [{ 'size': ['8pt', '9pt', '10pt', '12pt', '14pt', '16pt', '20pt', '24pt'] }],  // custom font sizes
            ['bold', 'italic', 'underline','strike'],
            [{ 'script': 'sub'}, { 'script': 'super' }], // Add this
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['blockquote'],
            ['csref-no'],
            [{'highlight': 'positive'}, {'highlight': 'warn'}, {'highlight': 'caution'}],
            [{'inline-direction': 'l'}, {'inline-direction': 'r'}],
            ['footnote'],
            [{'annotation': 'positive'}, {'annotation': 'warning'}, {'annotation': 'caution'}, {'annotation': 'general'}],
            ['clean'],
            ['image'],
        ],
    }
};
const fnModules = {
    toolbar: {
        container: [
            [{'align': []}], // text alignment
            [{'direction': 'rtl'}], // text direction
            [{ 'header': [1, 2, false] }],
            [{ 'size': ['8pt', '9pt', '10pt', '12pt', '14pt', '16pt', '20pt', '24pt'] }],  // custom font sizes
            ['bold', 'italic', 'underline','strike'],
            [{ 'script': 'sub'}, { 'script': 'super' }], // Add this
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['blockquote'],
            ['csref-no'],
            [{'inline-direction': 'l'}, {'inline-direction': 'r'}],
            ['clean'],
        ],
    }
};
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'align', 'direction', // added these
    'image',
    'script',
    'size',
    'csref-no',
    'searchlight',
    'inline-direction',
    'highlight',
    'footnote',
    'annotation',
];



const QuillEditor = ({ value = {}, onChange=()=>false, initialSearchParam="", conversion=null, readOnly = false, disabled = false, hidden = false, style={}, simpleMode=false }) => {

    const quill = useRef(null)
    const readOnlyDoc = useRef(null)
    const footerQuill = useRef(null)
    const annotationQuill = useRef(null)
    const annotationTitleRef = useRef(null)
    const [text, setText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [annotationForm, setAnnotationForm] = useState({ title: "", category: "", content: "" })
    const readerRef = useRef(null)

    const [activeFootnote, setActiveFootnote] = useState('');
    const [activeAnnotation, setActiveAnnotation] = useState('');
    const [footnotes, setFootnotes] = useState({})
    const [annotations, setAnnotations] = useState({})
    const [footnoteModalVisible, setFootnoteModalVisible] = useState(false);
    const [annotationModalVisible, setAnnotationModalVisible] = useState(false);

    const [contextMenuVisible, setContextMenuVisible] = useState(false)
    const [contextMenuId, setContextMenuId] = useState('')
    const [contextMenuPosition, setContextMenuPosition] = useState({top: 0, left: 0});
    const [annotationOpen, setAnnotationOpen] = useState(true);
    const [footnotesOpen, setFootnotesOpen] = useState(true);

    const [readOnlyDataModalVisible, setReadOnlyDataModalVisible] = useState(false);
    const [readOnlyDataViewer, setReadOnlyDataViewer] = useState({});
    const [quillReady, setQuillReady] = useState(false);

    const [searching, setSearching] = useState(false)
    const [searchParam, setSearchParam] = useState(initialSearchParam)
    const [activeSearchIndex, setActiveSearchIndex] = useState(0)
    const [totalSearchMatches, setTotalSearchMatches] = useState(0)


    const closeContextMenu = () => {
        setContextMenuVisible(false)
        setContextMenuId('')
    }

    const searchQuill = async(param) => {
        setSearching(true)
        await new Promise((r) => setTimeout(r, 0));
        const quillEditor = quill.current.getEditor()
        let text = quillEditor.getText()
        quillEditor.formatText(0, text.length, 'searchlight', false)
        // replace all non-break spaces with normal spaces for search purposes
        text = text.replace(/ /g, ' ')
        let count = 0;
        let indices = [];
        if(param.length > 0){
            let startIndex = 0;
            let index;
            while ((index = text.indexOf(param, startIndex)) !== -1) {
                indices.push(index);
                startIndex = index + param.length;
                count++;
            }
            const ops = [];
            let currentPos = 0;
            indices.forEach(idx => {
                ops.push({ retain: idx - currentPos });
                ops.push({ retain: param.length, attributes: { 'searchlight': true } });
                currentPos = idx + param.length;
            });
            quillEditor.updateContents({ ops });
        }
        setTotalSearchMatches(count)
        setActiveSearchIndex(0)
        setSearching(false)
    }

    useEffect(() => {
        if(quillReady && initialSearchParam && initialSearchParam.length > 0){
            searchQuill(initialSearchParam)
        }
    }, [initialSearchParam, quillReady])

    useEffect(() => {
        if(totalSearchMatches > 0 && readOnlyDoc.current){
            readOnlyDoc.current.querySelectorAll('.searchlight-active').forEach(el => el.classList.remove('searchlight-active'))
            const el = readOnlyDoc.current.querySelectorAll('.searchlight')[activeSearchIndex]
            if(el){
                el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
                el.classList.add('searchlight-active')
            }
        }
    }, [activeSearchIndex, totalSearchMatches])

    useEffect(() => {
        if (quill.current && value?.content) {
            let fn = {};
            value.footnotes?.forEach(note => {
                const fnDelta = new Delta(JSON.parse(note.content));
                quill.current.getEditor().setContents(fnDelta);
                fn[note.key] = {
                    delta: fnDelta,
                    html: quill.current.getEditor().root.innerHTML
                };
            });
            setFootnotes(fn);
            let ann = {};
            value.annotations?.forEach(a => {
                const annDelta = new Delta(JSON.parse(a.content));
                quill.current.getEditor().setContents(annDelta);
                ann[a.key] = {
                    delta: annDelta,
                    html: quill.current.getEditor().root.innerHTML,
                    type: a.type,
                    title: a.title,
                    category: a.category,
                };
            });
            setAnnotations(ann);
            const delta = new Delta(JSON.parse(value.content));
            quill.current.getEditor().setContents(delta);
        } else if(quill.current && simpleMode && value){
            const delta = new Delta(JSON.parse(value));
            quill.current.getEditor().setContents(delta);
        }
        const handleContextMenu = (e) => {
            if(e.target && e.target.classList.contains('annotation')){
                e.preventDefault()
                setContextMenuPosition({top: e.clientY+15, left:e.clientX});
                setContextMenuVisible(!contextMenuVisible)
                setContextMenuId(e.target.getAttribute('data-annid'))
                // alert(`You've right-clicked on an annotation with ID: ${e.target.getAttribute('data-xid')}`);
            }
        }
        quill.current?.getEditor()?.root?.addEventListener('contextmenu', handleContextMenu)
        setQuillReady(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        
        quill.current.clipboard?.addMatcher('span.csref-no', function(node, delta) {
            let newDelta = new Quill.Delta();
            delta.ops.forEach(op => {
                if (typeof op.insert === 'string') {
                    newDelta.insert(op.insert, { 'csref-no': true });
                } else {
                    newDelta.push(op);
                }
            });
            return newDelta;
        });
    }, [quill])

    useEffect(() => {
        if(conversion){
            let editor = quill.current.getEditor();
            let newFns = []
            Object.keys(conversion.footnotes).forEach(key => {
                editor.clipboard.dangerouslyPasteHTML(conversion.footnotes[key]);
                newFns.push({key, content: JSON.stringify(editor.getContents().ops)})
            })
            let newAnns = []
            conversion.annotations?.forEach(a => {
                editor.clipboard.dangerouslyPasteHTML(a.content);
                newAnns.push({key: a.key, content: JSON.stringify(editor.getContents().ops), type: a.type, title: a.title, category: a.category})
            })
            editor.clipboard.dangerouslyPasteHTML(conversion.content);
            const content = JSON.stringify(editor.getContents().ops);

            async function setDeltaRequest(conversion, data) {
                const response = await conversion.setDeltaRequest({id: conversion.id, Delta: data})
                handleResponse(response)
            }
            setDeltaRequest(conversion, {content, footnotes: newFns, annotations: newAnns});
            editor.setContents([]);
        }
    }, [conversion])
    
    useEffect(() => {
        if (readOnly || disabled) {
            quill.current.getEditor().enable(false);
        } else {
            quill.current.getEditor().enable(true);
        }
    }, [readOnly, disabled]);

    const parseData = (data) => (simpleMode ? JSON.stringify(data.content.ops) : { content: JSON.stringify(data.content.ops), footnotes: Object.entries(data.footnotes).map(([key, value]) => ({ key, content: JSON.stringify(value.delta.ops) })), annotations: Object.entries(data.annotations).map(([key, value]) => ({ key, content: JSON.stringify(value.delta.ops), type: value.type, title: value.title, category: value.category })) })
    

    useEffect(() => {
        const toolbar = quill.current.getEditor().getModule('toolbar');
        toolbar.addHandler('footnote', addFootnote);
        toolbar.addHandler('annotation', addAnnotation);
        quill.current.getEditor().on('text-change', () => updateFootnotes())
        quill.current.getEditor().on('text-change', () => updateAnnotations())
        quill.current.getEditor().on('text-change', () => onChange(parseData({content: quill.current.getEditor().getContents(), footnotes: footnotes, annotations: annotations})))
        quill.current.getEditor().format('direction', 'rtl');
        quill.current.getEditor().format('align', 'right');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        onChange(parseData({content: quill.current.getEditor().getContents(), footnotes: footnotes, annotations: annotations}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [footnotes, annotations])

    const updateFootnotes = () => {
        const quillEditor = quill.current.getEditor();
        const length = quillEditor.getLength();
        const footnotesInEditor = [];
        for (let i = 0; i < length; i++) {
            const [blot] = quillEditor.getLeaf(i);
            if (blot.parent instanceof FootnoteBlot) {
                footnotesInEditor.push(blot.parent.domNode.getAttribute('data-xid'));
            }
        }
        setFootnotes(prevFootnotes => {
            const activeFootnotes = {...prevFootnotes};
            // remove additional footnotes
            Object.keys(activeFootnotes).forEach(id => {
                if (!footnotesInEditor.includes(id)) {
                    delete activeFootnotes[id];
                }
            });
            // create empty delta for newly pasted footnotes via HTML
            footnotesInEditor.forEach(id => {
                if (!activeFootnotes[id]) {
                    activeFootnotes[id] = {
                        delta: new Delta([]),
                        html: ""
                    };
                }
            });
            return activeFootnotes;
        });
    }

    const updateAnnotations = () => {
        const quillEditor = quill.current.getEditor();
        const length = quillEditor.getLength();
        const annotationsInEditor = [];
        for (let i = 0; i < length; i++) {
            const [blot] = quillEditor.getLeaf(i);
            if (blot.parent instanceof AnnotationBlot) {
                const classList = Array.from(blot.parent.domNode.classList).filter(className => className.startsWith('ann_'));
                const type = classList.length > 0 ? classList[0].replace('ann_', '') : null;
                if(type && blot.parent.domNode.getAttribute('data-annid')){
                    annotationsInEditor.push({id: blot.parent.domNode.getAttribute('data-annid'), type: type});
                }
            }
        }
        setAnnotations(prevAnnotations => {
            const activeAnnotations = {...prevAnnotations};
            // remove additional annotations
            Object.keys(activeAnnotations).forEach(id => {
                if (annotationsInEditor.filter(aie => aie.id === id).length === 0) {
                    delete activeAnnotations[id];
                }
            });
            // create empty delta for newly pasted annotations via HTML
            annotationsInEditor.forEach(aie => {
                if (!activeAnnotations[aie.id]) {
                    activeAnnotations[aie.id] = {
                        delta: new Delta([]),
                        html: "",
                        type: aie.type,
                        title: "",
                        category: "",
                    };
                }
            });
            return activeAnnotations;
        });
    }

    const removeAnnotation = (key) => {
        const quillEditor = quill.current.getEditor();
        const length = quillEditor.getLength();
        for (let i = 0; i < length; i++) {
            const [blot] = quillEditor.getLeaf(i);
            if (blot.parent instanceof AnnotationBlot && blot.parent.domNode.getAttribute('data-annid') === key) {
                // quillEditor.removeFormat((i > 0 ? i-1 : 0), (i > 0 ? i-1 : 0)+1);
                const start = blot.offset(quillEditor.scroll);
                const annotationContent = blot.parent.domNode.innerText;
                quillEditor.deleteText(start, annotationContent.length);
                quillEditor.insertText(start, annotationContent);
            }
        }
        setAnnotations(prevAnnotations => {
            const { [contextMenuId]: _, ...rest } = prevAnnotations;
            return rest;
        });
    }

    useEffect(() => {
        if(! activeFootnote){
            setFooterText('')
        }
    }, [activeFootnote])

    useEffect(() => {
        if(! activeAnnotation){
            setAnnotationForm({ title: "", category: "", content: "" })
        }
    }, [activeAnnotation])

    const addAnnotation = (type) => {
        if (readOnly || disabled) return;
        const selection = quill.current.getEditor().getSelection();
        if(selection && selection.length > 0){
            let hasAnnotationBlot = false;
            for (let i = selection.index; i < selection.index + selection.length; i++) {
                const [leaf] = quill.current.getEditor().getLeaf(i);
                if (leaf.parent instanceof AnnotationBlot) {
                  hasAnnotationBlot = true;
                  break;
                }
            }
            // Prevent Overwriting Annotation Blots
            if(hasAnnotationBlot){
                notify({status: 'error', message: "ކޮމެންޓް އޯވަރައިޓެއް ނުކުރެވޭނެ"})
                return
            }
            const index = selection.index;
            const id = Math.random().toString(36).substring(2, 15);
            quill.current.getEditor().formatText(index, selection.length, {'annotation': {id: id, type: type}})
            setActiveAnnotation(id)
            setAnnotationModalVisible(true)
        }
    }

    const addFootnote = () => {
        if (readOnly || disabled) return;
        const selection = quill.current.getEditor().getSelection();
        if (selection) {
          const index = selection.index;
          const id = Math.random().toString(36).substring(2, 15);
          quill.current.getEditor().insertText(index, '﻿', {'footnote': id});
          setActiveFootnote(id);
          setFootnoteModalVisible(true)
        }
    };

    const toggleFootnoteModal = () => {
        if(footnoteModalVisible){
            setActiveFootnote('')
        }
        setFootnoteModalVisible(!footnoteModalVisible)
    }

    const toggleAnnotationModal = () => {
        if(annotationModalVisible){
            setActiveAnnotation('')
        }
        setAnnotationModalVisible(!annotationModalVisible)
    }

    const toggleReadOnlyDataModal = () => {
        if(readOnlyDataModalVisible){
            setReadOnlyDataViewer({})
        }
        setReadOnlyDataModalVisible(!readOnlyDataModalVisible)
    }


    useEffect(() => {
        if(annotationModalVisible){
            setTimeout(() => {
                annotationTitleRef.current?.focus()
                setAnnotationForm({...annotationForm})
            }, [50])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotationModalVisible])
    
    
    useEffect(() => {
        const contentFtntes = readerRef.current.querySelectorAll('.content-div .ftnte');
        const ftnteContainers = readerRef.current.querySelectorAll('.ftnte-container');
        
        const handleFooterClick = (event) => {
            const ftnteId = event.target.getAttribute('data-xid');
            if(ftnteId){
                const targetContainer = readerRef.current.querySelector(`.content-div .ftnte[data-xid="${ftnteId}"]`);
                if (targetContainer) {
                    targetContainer.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        const handleContentClick = (event) => {
            const ftnteId = event.target.getAttribute('data-xid');
            if(ftnteId){
                setReadOnlyDataModalVisible(true)
                setReadOnlyDataViewer({ type: "Footnotes", data: footnotes[ftnteId] })
            }
            // const targetContainer = readerRef.current.querySelector(`.ftnte-container[data-xid="${ftnteId}"]`);
            // if (targetContainer) {
                // targetContainer.scrollIntoView({ behavior: 'smooth' });
            // }
        }

        ftnteContainers.forEach((ftnte) => ftnte.addEventListener('click', handleFooterClick));
        contentFtntes.forEach((ftnte) => ftnte.addEventListener('click', handleContentClick));

        return () => {
            ftnteContainers.forEach((ftnte) => ftnte.removeEventListener('click', handleFooterClick));
            contentFtntes.forEach((ftnte) => ftnte.removeEventListener('click', handleContentClick));
        };
    }, [text, footnotes]);

    useEffect(() => {
        if(activeAnnotation && annotationQuill.current){
            setAnnotations({...annotations, [activeAnnotation]: { ...annotations[activeAnnotation], delta: annotationQuill.current.getEditor().getContents(), html: annotationForm.content, title: annotationForm.title, category: annotationForm.category }})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotationForm])
    
    if (hidden) return null;



    return (
        <React.Fragment>
            <div style={readOnly ? {display:'none'} : {}}>
                <ReactQuill style={{...style, background: 'white'}} ref={quill} value={text} onChange={(e)=>setText(e)} modules={simpleMode ? fnModules : modules} formats={formats} />
                {Object.keys(footnotes).length ? (
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5"><i className="fas fa-star-of-life fa-sm"></i> ފުޓްނޯޓްތައް ({Object.keys(footnotes).length}) <i style={{cursor: "pointer"}} className={"fa fa-"+(footnotesOpen ? "minus" : "plus")+" fa-sm"} onClick={() => setFootnotesOpen(!footnotesOpen)}></i></CardTitle>
                                        <div className={"collapsible " + (footnotesOpen ? "" : "closed")}>
                                            {Object.keys(footnotes).map((id,key) => (
                                                <CardText key={id}>
                                                    <u>ފުޓްނޯޓް {key+1}</u> | <i className="fas fa-edit fa-sm" onClick={() => {
                                                        setActiveFootnote(id)
                                                        setFooterText(footnotes[id].html)
                                                        setFootnoteModalVisible(true)
                                                    }} style={{cursor:'pointer'}}></i> |
                                                    <span dangerouslySetInnerHTML={{ __html: footnotes[id]?.html || "" }} />
                                                </CardText>
                                            ))}
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : null}
                {Object.keys(annotations).length ? (
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5"><i className="fa fa-comment fa-sm"></i> ކޮމެންޓްތައް ({Object.keys(annotations).length}) <i style={{cursor: "pointer"}} className={"fa fa-"+(annotationOpen ? "minus" : "plus")+" fa-sm"} onClick={() => setAnnotationOpen(!annotationOpen)}></i></CardTitle>
                                        <div className={"collapsible " + (annotationOpen ? "" : "closed")}>
                                            {Object.keys(annotations).map((id,key) => (
                                                <CardText key={id}>
                                                    <span className={"ann_bullet "+annotations[id]?.type}>◀</span><u>&nbsp;ކޮމެންޓް {key+1}</u><span>{annotations[id]?.title ? ` (${annotations[id].title})` : ""}</span> | <i className="fas fa-edit fa-sm" onClick={() => {
                                                        setActiveAnnotation(id)
                                                        setAnnotationForm({ title: annotations[id].title, category: annotations[id].category, content: annotations[id].html})
                                                        setAnnotationModalVisible(true)
                                                    }} style={{cursor:'pointer'}}></i> | <i className="fas fa-trash-alt fa-sm" onClick={() => {
                                                        removeAnnotation(id)
                                                    }} style={{cursor:'pointer'}}></i>
                                                    <span dangerouslySetInnerHTML={{ __html: annotations[id]?.html || "" }} />
                                                </CardText>
                                            ))}
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : null}

                <Modal size="xl" isOpen={footnoteModalVisible} toggle={()=>toggleFootnoteModal()}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">ފުޓްނޯޓް</h5>
                        <button type="button" onClick={() => toggleFootnoteModal({})} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <React.Fragment>
                        <div id="modalBody" className="modal-body">
                            <ReactQuill ref={footerQuill} value={footerText} modules={fnModules} formats={formats} onChange={(e)=>{
                                if(activeFootnote && footerQuill.current){
                                    setFooterText(e)
                                    setFootnotes({...footnotes, [activeFootnote]: { delta: footerQuill.current.getEditor().getContents(), html: e }})
                                }
                            }} />
                        </div>
                    </React.Fragment>
                </Modal>

                <Modal size="xl" isOpen={annotationModalVisible} toggle={()=>toggleAnnotationModal()}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">ކޮމެންޓް</h5>
                        <button type="button" onClick={() => toggleAnnotationModal({})} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <React.Fragment>
                        <div id="modalBody" className="modal-body">                           
                             <Row>
                                <Col xs={12} sm={6}>
                                    <label htmlFor="މާއްދާ" className="form-label">މާއްދާ </label>
                                    <input ref={annotationTitleRef} type="text" className="dv form-control" placeholder="މާއްދާ" value={annotationForm?.title || ""} onChange={(e) => setAnnotationForm({...annotationForm, title: e.target.value})} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label htmlFor="ކެޓެގަރީ" className="form-label">ކެޓެގަރީ </label>
                                    <Select className="dv select2-selection" 
                                            options={[{value: 'އެޑިޓަރުގެ ކޮމެންޓް', label: 'އެޑިޓަރުގެ ކޮމެންޓް'},{value: 'އުސޫލު', label: 'އުސޫލު'}]} 
                                            value={annotationForm?.category ? {label: annotationForm?.category, value: annotationForm?.category} : ''}
                                            onChange={(e)=>setAnnotationForm({...annotationForm, category: e.value})} 
                                            placeholder="ނަންގަވާ..." />
                                </Col>
                                <Col xs={12}>
                                    <label htmlFor="ކޮންޓެންޓް" className="form-label mt-4">ކޮންޓެންޓް </label>
                                    <ReactQuill ref={annotationQuill} value={annotationForm?.content} modules={fnModules} formats={formats} onChange={(e)=>{
                                        if(activeAnnotation && annotationQuill.current && annotationForm.content !== e){
                                            setAnnotationForm({...annotationForm, content: e})
                                        }
                                    }} />
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                </Modal>

                <Menu disableAutoFocusItem anchorReference="anchorPosition" anchorPosition={contextMenuPosition} keepMounted open={Boolean(contextMenuVisible)} onClose={()=>closeContextMenu()}>
                    <DropdownItem toggle={false} onClick={()=>{
                        setActiveAnnotation(contextMenuId)
                        setAnnotationForm({ title: annotations[contextMenuId].title, category: annotations[contextMenuId].category, content: annotations[contextMenuId].html })
                        setAnnotationModalVisible(true)
                        closeContextMenu()
                    }} key={1}><span className={"mdi mdi-pencil d-block font-size-14"}>{" "}އެޑިޓް</span></DropdownItem>
                    <DropdownItem toggle={false} onClick={()=>{
                        removeAnnotation(contextMenuId)
                        closeContextMenu()
                    }} key={2}><span className={"mdi mdi-trash-can d-block font-size-14"}>{" "}ޑިލީޓް</span></DropdownItem>
                </Menu>
            </div>
            
            <div ref={readerRef} style={readOnly ? {} : {display:'none'}}>
                <Row className="content-div ql-reader m-4">
                    <Row className="justify-content-center">
                        <Col xs={12} style={{position: 'fixed', zIndex: 999, backgroundColor:'rgba(0,0,0,0.5)'}} align="center">
                            <div className="search-box mb-3">
                                <input  type="text" className="dv"  value={searchParam} placeholder="ހޯދާ..."  onChange={(e) => setSearchParam(e.target.value)} onKeyUp={(e) => e.key === "Enter" && searchQuill(searchParam)} />
                                <div dir="ltr">
                                    <span>{totalSearchMatches > 0 ? (activeSearchIndex+1) : 0} / {totalSearchMatches}</span>
                                    <button onClick={() => setActiveSearchIndex((prevIndex) => (prevIndex - 1 + totalSearchMatches) % totalSearchMatches)}>
                                        <i className="mdi mdi-chevron-up"></i>
                                    </button>
                                    <button onClick={() => setActiveSearchIndex((prevIndex) => (prevIndex + 1) % totalSearchMatches)}>
                                        <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                    <button onClick={() => searchQuill(searchParam)}>
                                        <span className={searching ? "dot-bounce" : "mdi mdi-magnify"}></span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div ref={readOnlyDoc} onClick={(e) => {
                        if(e.target.getAttribute('data-annid')){
                            setReadOnlyDataModalVisible(true)
                            setReadOnlyDataViewer({ type: "Annotation", data: annotations[e.target.getAttribute('data-annid')] })
                        }
                    }} dangerouslySetInnerHTML={{ __html: text }} />
                </Row>
                <hr />
                <Row className="footnotes-div ql-reader">
                    {Object.keys(footnotes).length > 0 ? (
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5"><i className="fas fa-star-of-life fa-sm"></i> ފުޓްނޯޓްސް</CardTitle>
                                        {Object.keys(footnotes).map((id,key) => (
                                            <CardText key={id} className="ftnte-container" data-xid={id}>
                                                <u>ފުޓްނޯޓް {key+1}</u>
                                                <span dangerouslySetInnerHTML={{ __html: footnotes[id]?.html || "" }} />
                                            </CardText>
                                        ))}
                                </CardBody>
                            </Card>
                        </Col>
                    ) : null}
                </Row>
                
                <Modal size="xl" isOpen={readOnlyDataModalVisible} toggle={()=>toggleReadOnlyDataModal()}>
                    <div className={("modal-header " + (readOnlyDataViewer?.data?.type+"-background"))}>
                        <h5 className="modal-title mt-0" id="myModalLabel">{readOnlyDataViewer?.type === "Annotation" ? "ކޮމެންޓް" : "ފުޓްނޯޓް"}</h5>
                        <button type="button" onClick={() => toggleReadOnlyDataModal()} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        <div id="modalBody" className="modal-body">
                            {readOnlyDataViewer?.type === "Annotation" ? (
                                <React.Fragment>
                                    <Row className="m-4">
                                        <Col xs={12} sm={6}>
                                            <label htmlFor="މާއްދާ" className="form-label"><u>މާއްދާ</u> </label>
                                            <h4>{readOnlyDataViewer?.data?.title || "-"}</h4>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <label htmlFor="ކެޓެގަރީ" className="form-label"><u>ކެޓެގަރީ</u> </label>
                                            <h4>{readOnlyDataViewer?.data?.category || "-"}</h4>
                                        </Col>
                                        <Col xs={12} className="mt-4">
                                            {/* <label htmlFor="ކޮންޓެންޓް" className="form-label"><u>ކޮންޓެންޓް</u> </label> */}
                                            <span dangerouslySetInnerHTML={{ __html: readOnlyDataViewer?.data?.html || "" }} />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : null}
                            {readOnlyDataViewer?.type === "Footnotes" ? (
                                <React.Fragment>
                                    <Row>
                                        <Col xs={12}>
                                            {/* <label htmlFor="ކޮންޓެންޓް" className="form-label"><u>ކޮންޓެންޓް</u> </label> */}
                                            <span dangerouslySetInnerHTML={{ __html: readOnlyDataViewer?.data?.html || "" }} />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : null}
                        </div>
                </Modal>
            </div>
        </React.Fragment>
        
    )
    
}

export default QuillEditor;

