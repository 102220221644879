import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
// Redux
import { Link, useLocation, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import profile from "../../assets/images/logo-dark.png";
import profileLight from "../../assets/images/logo-light-1.png";
import logo from "../../assets/images/logo.svg";
import { validatePasswordReset, initPasswordReset } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { preloaderState, themeTypeState } from "../../state/GlobalState";
import notify from "../../custom/helpers/Notify";
import { passwordResetHashState } from "../../api/state/AuthState";
import { Loader } from '../../custom/components/Loader';

const ForgotPassword = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [preloader, setPreloader] = useRecoilState(preloaderState)
  const setPasswordResetHash = useSetRecoilState(passwordResetHashState)
  const [form, setForm] = useState({ email: '' })
  const themeType = useRecoilValue(themeTypeState)

  const location = useLocation()

  useEffect(() => {
    setPreloader(true)
    if(location.search){
      var params = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      if(params.ref){
        async function passwordResetFn(params){
          const response = await validatePasswordReset({ ref: params.ref })
          if(response?.Status === "success" && response?.Ref){
            setPasswordResetHash(response.Ref)
            setPreloader(false)
            history.push("/change-password")
          } else {
            setPreloader(false)
            notify({ status: 'error', message: "Invalid Password Reset Link. Please try again later!" });
            history.push("/login")
          }
        }
        passwordResetFn(params)
      } else {
        setPreloader(false)
        history.push("/login")
      }
    } else {
      setPreloader(false)
    }
  }, [history, location.search, setPasswordResetHash, setPreloader]);

  // handleValidSubmit
  const handleValidSubmit = async(event) => {
    event.preventDefault()
    setSubmitDisabled(true)
    const response = await initPasswordReset({ Email: form.email })
    handleResponse(response)
    if(response?.Status === "success"){
      setForm({ email: '' })
    }
    setSubmitDisabled(false)
  }


  return (
    preloader ? (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Loader loading={submitDisabled} transparent />
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className={(themeType === 'dark' ? 'text-white' : 'text-primary')}>ޕާސްވޯޑް ހަނދާންނެތުނީ؟</h5>
                          <p>ކުރިޔަށް ދާން އީމއެިލް ޖައްސަވާ.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-center">
                          <img width="90%" src={profile} alt="shuna.law" className="img-fluid dark-logo" />
                          <img width="90%" src={profileLight} alt="shuna.law" className="img-fluid light-logo" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">

                      <Form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
                        <FormGroup>
                          <Label>އީމެއެިލް</Label>
                          <Input name="email" label="Email" className="form-control enf" placeholder="Email" value={form.email} onChange={(e)=>setForm({...form, email: e.target.value})} type="email" required />
                        </FormGroup>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button className="btn btn-primary w-100 waves-effect waves-light" disabled={submitDisabled} type="submit">ރީސެޓް</button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className={(themeType === 'dark' ? 'text-white' : '')} ><span className='bg-span'>އަނބުރާ <Link to="login" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}> ލޮގިން ޕޭޖަށް؟ </Link> </span></p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  );
}
export default withRouter(ForgotPassword);
