import React, { useEffect, useState } from 'react';
import MceEditor from "../../custom/components/form-elements/MceEditor";
import classnames from 'classnames';
import { Row, Col, Card, CardTitle, CardBody, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import moment from 'moment';
import { getRefByCNRequest } from '../../api/controller/GuestController';
import { themeTypeState } from '../../state/GlobalState';
import { useRecoilValue } from 'recoil';

moment.updateLocale('dhivehi', {
    months : ['ޖަނަވަރި', 'ފެބްރުއަރި', 'މާރިޗް', 'އެޕްރީލް', 'މޭ', 'ޖޫން', 'ޖުލައި', 'އޮގަސްޓް', 'ސެޕްޓެމްބަރު', 'އޮކްޓޯބަރު', 'ނޮވެމްބަރު', 'ޑިސެމްބަރު']
});
moment.locale('dhivehi');


export const CaseContent = ({data, visible=true, searchedQuery="", initialTab=1, referrer="modal"}) => {

    const [deviceHeight, setDeviceHeight] = useState(window.innerHeight)
    const [activeTab, setActiveTab] = useState(1);

    const themeType = useRecoilValue(themeTypeState);
    
    useEffect(() => {
        const handleResize = () => setDeviceHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setActiveTab(initialTab)
    }, [visible, initialTab])

    return (
        <div className={"wizard clearfix case-viewer" + (themeType === "dark" ? " dark-case-viewer" : "")} style={{width:'100%'}}>
            <div className="steps clearfix">
                <ul>
                    <NavItem onClick={()=>setActiveTab(1)} className={classnames({ current: activeTab === 1 })}>
                        <NavLink className={classnames({ active: activeTab === 1 })} >
                            <span className="number mr-2" style={{fontFamily:'Times New Roman'}}><span className="mdi mdi-eye"></span></span>
                            <span className="step-title">ތަފްޞީލު</span>
                        </NavLink>
                    </NavItem>
                    <NavItem onClick={()=>setActiveTab(2)} className={classnames({ current: activeTab === 2 })}>
                        <NavLink className={classnames({ active: activeTab === 2 })} >
                            <span className="number mr-2" style={{fontFamily:'Times New Roman'}}><span className="mdi mdi-file-document"></span></span>
                            <span className="step-title">ޤަޒިއަޔާ</span>
                        </NavLink>
                    </NavItem>
                </ul>
                <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-contenmt-4" style={{padding: '0 1em'}}>
                    <TabPane className="mt-4" key={1} tabId={1}>
                        <Row>
                            <Col xs={12} md={data?.judges?.length > 0 ? 6 : 12}>
                                <p className="size1-3"><b><u>ޤަޒިއްޔާ ނަންބަރު:</u></b> {data?.case_numbers?.map((c,k)=><React.Fragment key={k}><span className="csref-no">{c.number}</span>&nbsp;</React.Fragment>)}</p>
                                <p className="size1-3"><b><u>ދަޢުވާ ކުރާ ފަރާތް:</u></b> {data?.claimant?.replaceAll("|"," | ")}</p>
                                <p className="size1-3"><b><u>ދަޢުވާ ރައްދުވާ ފަރާތް:</u></b> {data?.respondant?.replaceAll("|"," | ")}</p>
                                {data?.interpleader && <p className="size1-3"><b><u>ތަދައްތަދައްޙުލްވި ފަރާތް:</u></b> {data?.interpleader?.replaceAll("|"," | ")}</p>}
                                <p className="size1-3"><b><u>މައްސަލާއިގެ ބާވަތް:</u></b> {data?.case_types_text}</p>
                            </Col>
                            <Col xs={12} md={data?.judges?.length > 0 ? 6 : 12}>
                                <p className="size1-3 mb-2"><b><u>ނިންމެވި ކޯޓު:</u></b> {data?.court?.name}</p>
                                <p className="size1-3 mb-2"><b><u>ނިމުނު ތާރީޙް:</u></b> &lrm;{moment(data?.closed_date)?.format("DD MMMM YYYY")}</p>
                                <p className="size1-3 mb-2"><b><u>ފަނޑިޔާރުން:</u></b></p>
                                {data?.judges?.map((j,k)=><li key={k}>{j.name}</li>)}
                            </Col>
                            {data?.status_details ? (
                                <Col xs={12}>
                                    <hr />
                                    <p className="size1-3 mb-2"><b><u>ސްޓޭޓަސްގެ ތަފްޞީލު:</u></b> {data?.statuses?.map((s,k)=><React.Fragment key={k}><span title={s.name} className={s.icon+" custom-status"} style={{backgroundColor:s.color, paddingRight:'3px', paddingLeft:'3px', fontSize: "1.3em"}}></span>&nbsp;</React.Fragment>)}</p>
                                    <div className="size1-3 p-3" dangerouslySetInnerHTML={{ __html: data?.status_details }} />
                                </Col>
                            ) : (
                                data?.statuses?.length > 0 && <Col xs={12}>
                                    <p className="size1-3 mb-2"><b><u>ސްޓޭޓަސް:</u></b> {data?.statuses?.map((s,k)=><React.Fragment key={k}><span title={s.name} className={s.icon+" custom-status"} style={{backgroundColor:s.color, paddingRight:'3px', paddingLeft:'3px', fontSize: "1.3em"}}></span>&nbsp;</React.Fragment>)}</p>
                                </Col>
                            )}
                            {data?.related_cases?.length > 0 && <Col xs={12}>
                                <hr />
                                <p className="size1-4 mb-2"><b><u>ގުޅުންހުރި ޤަޒިއްޔާތައް:</u></b></p>
                                {data.related_cases.map((rc,k)=><React.Fragment key={k}>
                                    <Card className="mb-0">
                                        <CardTitle>
                                            <span style={{fontSize:'1.4em'}}>({k+1})</span> <span onClick={async()=>{
                                                const response = await getRefByCNRequest({ CaseNumber: rc.number })
                                                if(response?.Status === "success"){
                                                    window.open(`/case?ref=${response?.Reference}`, "_blank")
                                                }
                                            }} className="csref-no size1-2">{rc.number}</span>&nbsp;
                                            {rc.status && <span title={rc.status.name} className={rc.status.icon+" custom-status"} style={{backgroundColor:rc.status.color, paddingRight:'3px', paddingLeft:'3px', fontSize: "1.3em"}}></span>}
                                        </CardTitle>
                                        <CardBody style={{paddingTop:0, paddingBottom:0}}>
                                            <p className="size1-3"><b><u>ބާވަތް:</u></b> {rc.type?.name}</p>
                                            {rc?.description && <div className="size1-3 p-3" dangerouslySetInnerHTML={{ __html: rc?.description }} />}
                                        </CardBody>
                                    </Card>
                                    <hr/>
                                </React.Fragment>)}
                            </Col>}
                        </Row>
                    </TabPane>
                    <TabPane key={2} tabId={2}>
                        <Row>
                            {activeTab === 2 && <div key={data?.__clickIndex}><MceEditor value={data?.case_content} readOnly={true} initialSearch={searchedQuery} searchClickIndex={data?.__clickIndex || 0} height={referrer === "modal" ? (deviceHeight - ((108 + 63))) : (deviceHeight-108) + "px"} /></div>}
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )

}